import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import {
  setInstrumentEquipments,
  useGetPersonnel,
  useInstrumentEquipments,
  useInstrumentEvents,
  useInstrumentSettings,
  useInstrumentVendors
} from '../../../util/db';
import { listInstrumentColumns } from './Columns';
import ModalFormInstrument from '../Modal/ModalFormInstrument';
import { SETTING_TYPE } from '../TabSettings/DefineCategories';
import moment from 'moment';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';
import { renderMUIButtonWithPermissions } from '../../../util/util';
import { useAuth } from '../../../util/auth';

const TabInstruments = () => {
  const auth = useAuth();
  const [filterModel, setFilterModel] = useState({});
  const { data: events = [] } = useInstrumentEvents();
  const { data: rawInstruments = [] } = useInstrumentEquipments(filterModel);
  const { data: vendors = [] } = useInstrumentVendors();
  const { data: instrumentSettingsInstrumentStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );
  const { data: instrumentSettingsInstrumentType = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_TYPE
  );
  const { data: instrumentSettingsInstrumentParameter = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_PARAMETER
  );

  const { data: allLocations = [] } = useInstrumentSettings(SETTING_TYPE.INSTRUMENT_LOCATION);

  const { data: personnel } = useGetPersonnel();
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  const handleSave = async (data) => {
    await setInstrumentEquipments(data);
    setOpen(false);
  };

  const handleChange = (field, event) => {
    if (event.target.value) {
      setFilterModel({
        ...filterModel,
        [field]: event.target.value
      });
    } else {
      setFilterModel({
        ...filterModel,
        [field]: ''
      });
    }
  };

  const now = moment();
  const next30DaysEvents = events
    .filter((event) => {
      return (
        event.dateDue?.seconds * 1000 >= now.startOf('day').valueOf() &&
        event.dateDue?.seconds * 1000 <= moment().add(30, 'days').endOf('day').valueOf() &&
        event.status !== 'Complete'
      );
    })
    .sort((a, b) => a.dateDue.seconds - b.dateDue.seconds);

  const instruments = rawInstruments.map((row) => {
    const dataStatus = instrumentSettingsInstrumentStatus.find((item) => item.id === row.status);
    const dataType = instrumentSettingsInstrumentType.find((item) => item.id === row.type);
    const dataParameter = instrumentSettingsInstrumentParameter.find(
      (item) => item.id === row.parameter
    );
    const responsibleParty = personnel.find((item) => item.id === row?.responsibleParty);
    const eventsOfInstrument = next30DaysEvents.filter((event) => event.instrumentName === row.id);
    const location = allLocations.find((l) => l.id === row.location);

    return {
      ...row,
      status: dataStatus?.name,
      type: dataType?.name,
      parameter: dataParameter?.name,
      responsibleParty: responsibleParty?.name,
      location: location?.name,
      nextEventDate: eventsOfInstrument.length
        ? moment(eventsOfInstrument[0].dateDue.seconds * 1000)
        : ''
    };
  });

  return (
    <>
      {hasPermission(permissionData, SCREEN.INSTRUMENT_INSTRUMENTS, RULES.VIEW) ? (
        <>
          <div className="top-actions">
            {renderMUIButtonWithPermissions(
              'Create new instrument',
              () => setOpen(true),
              SCREEN.INSTRUMENT_INSTRUMENTS,
              RULES.CREATE,
              permissionData
            )}
            <ModalFormInstrument
              show={isOpen}
              handleClose={() => setOpen(false)}
              handleSave={handleSave}
              allLocations={allLocations}
              vendors={vendors}
              instrumentSettingsInstrumentStatus={instrumentSettingsInstrumentStatus}
              instrumentSettingsInstrumentType={instrumentSettingsInstrumentType}
              instrumentSettingsInstrumentParameter={instrumentSettingsInstrumentParameter}
              personnel={personnel}
            />
          </div>
          <div className="filter">
            <p>
              <strong>Filter data by:</strong>
            </p>
            <div>
              <Row>
                <Form.Group as={Col} controlId="location">
                  <Form.Label>Location</Form.Label>
                  <Form.Select onChange={(event) => handleChange('location', event)}>
                    <option value="">All locations</option>
                    {allLocations.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="status">
                  <Form.Label>Instrument Status</Form.Label>
                  <Form.Select onChange={(event) => handleChange('status', event)}>
                    <option value="">All status</option>
                    {instrumentSettingsInstrumentStatus.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="type">
                  <Form.Label>Instrument Type</Form.Label>
                  <Form.Select onChange={(event) => handleChange('type', event)}>
                    <option value="">All type</option>
                    {instrumentSettingsInstrumentType.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="type">
                  <Form.Label>Instrument Parameter</Form.Label>
                  <Form.Select onChange={(event) => handleChange('parameter', event)}>
                    <option value="">All parameter</option>
                    {instrumentSettingsInstrumentParameter.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}></Form.Group>
                <Form.Group as={Col} controlId="vendors">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    placeholder="All fields"
                    onChange={(event) => setSearchValue(event.target.value)}
                  />
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="grid-view">
            <h2>Instruments</h2>
            <CustomTable
              numberOfRows={50}
              data={instruments.filter((row) => {
                if (searchValue) {
                  return (
                    row.serialNumber?.includes(searchValue) ||
                    row.location?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    row.parameter?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    row.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    row.parameter?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    row.serialNumber?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    row.manufacturer?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    row.model?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    row.status?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    row.type?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    moment(row.nextEventDate)
                      .format('MM/DD/YYYY')
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  );
                } else {
                  return true;
                }
              })}
              header={listInstrumentColumns}
              sort={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
              rowHeight="auto"
              sx={{
                '.MuiDataGrid-cell': {
                  minHeight: '52px !important'
                },
                '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
                  display: 'none'
                }
              }}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TabInstruments;
