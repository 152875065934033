import React from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from '../util/auth';

function getActivesItems(hostname) {
  // note: in a totally unintuitive fashion, all of the below are variables passed into a "hide" prop
  // so "false" means that it's turned on, and "true" means it's turned off

  if (hostname.match(/bluetide./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      iLCP: true,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: true
    };
  }
  if (hostname.match(/buckeye./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      iLCP: true,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/ctl./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      iLCP: true,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/cvr./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      iLCP: false,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/demo./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      iLCP: false,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: true
    };
  }
  if (hostname.match(/ggpetro./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      iLCP: false,
      instrument: true,
      productComp: false,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/hep./) || hostname.match(/hfsm./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      iLCP: true,
      instrument: true,
      productComp: false,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/mmp./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      iLCP: false,
      instrument: true,
      productComp: false,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/colpipe./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      instrument: false,
      iLCP: false,
      productComp: false,
      settings: false,
      fuelComp: false
    };
  }

  if (hostname.match(/(ser|ies|propet|lupton)./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      iLCP: false,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: true
    };
  }

  return {
    dashboard: false,
    qCDataCharts: false,
    masterData: false,
    notificationsManager: false,
    iLCP: false,
    instrument: false,
    productComp: false,
    settings: false,
    fuelComp: hostname.match(/netlify/)
  };
}

const HidLink = ({ to, children, hide }) => {
  if (hide) return null;

  return (
    <LinkContainer to={to}>
      <NavDropdown.Item active={false}>{children}</NavDropdown.Item>
    </LinkContainer>
  );
};

HidLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool.isRequired
};

const HidExtLink = ({ to, children, hide }) => {
  if (hide) return null;

  return (
    <NavDropdown.Item active={false} href={to}>
      {children}
    </NavDropdown.Item>
  );
};

HidExtLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool
};

HidExtLink.defaultProps = {
  hide: false
};

function NavbarCustom({ bg, variant, expand, logo }) {
  const auth = useAuth();

  const activeItems = getActivesItems(window.location.hostname);

  return (
    <Navbar bg={bg} variant={variant} expand={expand}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img className="d-inline-block align-top" src={logo} alt="Logo" height="55" />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav>
            {auth.user && (
              <NavDropdown id="dropdown" title="Account">
                <HidLink to="/dashboard" hide={activeItems.dashboard}>
                  Dashboard
                </HidLink>
                <HidLink to="/chart" hide={activeItems.qCDataCharts}>
                  QC Data and Charts
                </HidLink>
                <HidLink to="/personnel" hide={activeItems.masterData}>
                  Master Data
                </HidLink>
                <HidLink to="/scheduler" hide={activeItems.notificationsManager}>
                  Notifications Manager
                </HidLink>
                <HidLink to="/ilcp" hide={activeItems.iLCP}>
                  ILCP
                </HidLink>
                <HidLink to="/instrument/events" hide={activeItems.instrument}>
                  Instrument Management
                </HidLink>
                <HidLink to="/product" hide={activeItems.productComp}>
                  Product Comp
                </HidLink>
                <HidExtLink to="/fuelcomp" hide={activeItems.fuelComp}>
                  Fuel Comp
                </HidExtLink>
                <HidLink to="/settings/general" hide={activeItems.settings}>
                  Settings
                </HidLink>
                <Dropdown.Divider />
                <LinkContainer to="/auth/signout">
                  <NavDropdown.Item
                    active={false}
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}

            {!auth.user && (
              <Nav.Item>
                <LinkContainer to="/auth/signin">
                  <Nav.Link active={false}>Sign in</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

NavbarCustom.propTypes = {
  bg: PropTypes.string,
  variant: PropTypes.string,
  expand: PropTypes.string,
  logo: PropTypes.string
};

NavbarCustom.defaultProps = {
  bg: 'light',
  variant: 'light',
  expand: 'md',
  logo: 'https://via.placeholder.com/150'
};

export default NavbarCustom;
