import React, { useEffect, useState } from 'react';
import CustomTable from '../../Table/CustomTable';
import { associatedEventColumns } from './Columns';
import {
  setInstrumentEvents,
  useGetPersonnel,
  useInstrumentEquipments,
  useInstrumentEvents,
  useInstrumentSettings,
  useInstrumentVendors
} from '../../../util/db';
import { SETTING_TYPE } from './DefineCategories';
import { Col, Form, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { listEventColumnsFn } from '../TabEvents/Columns';
import { useAuth } from '../../../util/auth';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';
import { renderMUIButtonWithPermissions } from '../../../util/util';

const ReassignEvents = () => {
  const auth = useAuth();
  const [fromResponsiblePartyId, setFromResponsiblePartyId] = useState();
  const [toResponsiblePartyId, setToResponsiblePartyId] = useState();

  const { data: events = [] } = useInstrumentEvents({ responsiblePartyId: fromResponsiblePartyId });
  const { data: vendors = [] } = useInstrumentVendors();
  const { data: personnel = [] } = useGetPersonnel();
  const { data: instruments = [] } = useInstrumentEquipments();
  const { data: instrumentSettingsEventStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: instrumentSettingsEventType = [] } = useInstrumentSettings(SETTING_TYPE.EVENT_TYPE);
  const { data: instrumentSettingsLocation = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_LOCATION
  );
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  const handleSubmit = async () => {
    if (!fromResponsiblePartyId || !toResponsiblePartyId) {
      return;
    }

    await Promise.all(
      events
        .filter((e) => rowSelectionModel.includes(e.id))
        .map((e) => {
          return setInstrumentEvents({ ...e, responsibleParty: toResponsiblePartyId });
        })
    );

    setFromResponsiblePartyId('');
    setToResponsiblePartyId('');
  };

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  return (
    <>
      {hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.VIEW) ? (
        <>
          <div className="filter">
            <div>
              <Row className="flex-end">
                <Form.Group as={Col}>
                  <Form.Label>Responsible Party</Form.Label>
                  <Form.Select
                    value={fromResponsiblePartyId}
                    onChange={(event) => setFromResponsiblePartyId(event.target.value)}
                  >
                    <option value="">All Responsible Party</option>
                    {personnel.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Assign To</Form.Label>
                  <Form.Select
                    onChange={(event) => setToResponsiblePartyId(event.target.value)}
                    value={toResponsiblePartyId}
                  >
                    <option value="">All Responsible Party</option>
                    {personnel
                      ?.filter((p) => p?.id !== fromResponsiblePartyId)
                      .map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  {renderMUIButtonWithPermissions(
                    'Re-assign',
                    () => handleSubmit(),
                    SCREEN.INSTRUMENT_SETTINGS,
                    RULES.UPDATE,
                    permissionData
                  )}
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="grid-view">
            <h3>List of events with selected Responsible Party</h3>
            <div className="table-box">
              <CustomTable
                data={events.map((row) => {
                  const dataInstrument = instruments.find((item) => item.id === row.instrumentName);
                  const dataStatus = instrumentSettingsEventStatus.find(
                    (item) => item.id === row.status
                  );
                  const eventType = instrumentSettingsEventType.find(
                    (item) => item.id === row.eventType
                  );
                  const location = instrumentSettingsLocation.find(
                    (item) => item.id === dataInstrument?.location
                  );
                  return {
                    ...row,
                    location: location?.name,
                    instrumentId: dataInstrument?.id,
                    instrumentName: dataInstrument?.name,
                    status: dataStatus?.name,
                    eventType: eventType?.name
                  };
                })}
                header={listEventColumnsFn({ vendors, personnel })}
                numberOfRows={20}
                rowHeight="auto"
                sort={{ sorting: { sortModel: [{ field: 'eventNumber', sort: 'asc' }] } }}
                sx={{
                  '.MuiDataGrid-cell': {
                    minHeight: '52px !important'
                  },
                  '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
                    display: 'none'
                  }
                }}
                checkboxSelection={hasPermission(
                  permissionData,
                  SCREEN.INSTRUMENT_SETTINGS,
                  RULES.UPDATE
                )}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
              />
            </div>
          </div>
        </>
      ) : (
        <> </>
      )}
    </>
  );
};

export default ReassignEvents;
