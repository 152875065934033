import { Box, Button, CardContent, CardHeader, Collapse, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import {
  documentColumns,
  instrumentEventColumns,
  vendorColumns,
  statusHistoryColumns
} from './Columns';
import ModalAddVendor from '../Modal/ModalAddVendor';
import { Link, useHistory, useParams } from '../../../util/router';
import {
  deleteInstrumentDocuments,
  deleteInstrumentEquipments,
  deleteInstrumentEvents,
  setInstrumentDocuments,
  setInstrumentEquipments,
  getInstrumentStatusLog,
  useGetPersonnel,
  useInstrumentDocuments,
  useInstrumentEquipmentDetails,
  useInstrumentEvents,
  useInstrumentNotificationGroups,
  useInstrumentSettings,
  useInstrumentVendors
} from '../../../util/db';
import { DOCUMENT_LOG_TYPE, SETTING_TYPE } from '../TabSettings/DefineCategories';
import ModalFormInstrument from '../Modal/ModalFormInstrument';
import {
  alphaNumericSorter,
  formatCurrency,
  renderMUIButtonWithPermissions
} from '../../../util/util';
import moment from 'moment';
import ModalFormDocument from '../Modal/ModalFormDocument';
import { deleteFileAsync, updateMetadata, uploadFileAsync } from '../../../util/storage';
import ModalAddPerson from '../Modal/ModalAddPerson';
import { listNotificationGroupsColumns } from '../TabSettings/Columns';
import { Delete, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useAuth } from '../../../util/auth';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';

const InstrumentDetails = () => {
  const auth = useAuth();
  const history = useHistory();
  const { id } = useParams();
  const { data: personnel = [] } = useGetPersonnel();
  const { data: events = [] } = useInstrumentEvents({ instrumentName: id });
  const { data: rawEquipmentDetails = [] } = useInstrumentEquipmentDetails(id);
  const { data: vendors = [] } = useInstrumentVendors();
  const { data: documents = [] } = useInstrumentDocuments();
  const { data: instrumentSettingsInstrumentStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );
  const { data: instrumentSettingsInstrumentType = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_TYPE
  );
  const { data: instrumentSettingsInstrumentParameter = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_PARAMETER
  );
  const { data: allLocations = [] } = useInstrumentSettings(SETTING_TYPE.INSTRUMENT_LOCATION);
  const { data: instrumentSettingsDocumentType = [] } = useInstrumentSettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const { data: instrumentSettingsEventStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: instrumentStatusLogs = [] } = getInstrumentStatusLog(
    id,
    DOCUMENT_LOG_TYPE.INSTRUMENT
  );

  const [instrumentDetails, setInstrumentDetails] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [isOpenAddDocument, setOpenAddDocument] = useState(false);
  const [isOpenAddVendor, setOpenAddVendor] = useState(false);
  const [editingDocument, setEditingDocument] = useState(null);
  const { data: allNotificationGroup = [] } = useInstrumentNotificationGroups();
  const [isOpenAddPerson, setOpenAddPerson] = useState(false);
  const [isOpenUser, setToggleUser] = useState([]);
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  useEffect(() => {
    if (rawEquipmentDetails && rawEquipmentDetails.length) {
      setInstrumentDetails(rawEquipmentDetails[0]);
    }
  }, [rawEquipmentDetails]);

  const status = instrumentSettingsInstrumentStatus.find(
    (item) => item.id === instrumentDetails?.status
  );

  const type = instrumentSettingsInstrumentType.find((item) => item.id === instrumentDetails?.type);
  const parameter = instrumentSettingsInstrumentParameter.find(
    (item) => item.id === instrumentDetails?.parameter
  );
  const responsibleParty = personnel.find(
    (item) => item.id === instrumentDetails?.responsibleParty
  );
  const location = allLocations.find((item) => item.id === instrumentDetails?.location);
  const handleSave = async (data) => {
    await setInstrumentEquipments({ ...instrumentDetails, ...data });
    setOpen(false);
  };

  const handleDelete = async () => {
    if (
      confirm(
        events.length > 0
          ? 'Events are associated with this instrument. Are you sure you want to delete it?'
          : 'Are you sure to delete this equipment?'
      )
    ) {
      await deleteInstrumentEquipments(instrumentDetails);
      history.push('/instrument/list');
    }
  };

  const handleAddVendors = async (data) => {
    const selectedVendors = Object.keys(data).filter((vendorId) => data[vendorId]);
    await setInstrumentEquipments({
      ...instrumentDetails,
      vendors: [...(instrumentDetails.vendors || []), ...selectedVendors]
    });
    setOpenAddVendor(false);
  };

  const handleDeleteVendor = async (data) => {
    const remainingVendors = instrumentDetails.vendors.filter((vendor) => vendor !== data.id);
    await setInstrumentEquipments({
      ...instrumentDetails,
      vendors: remainingVendors
    });
  };

  const handleDeleteDocument = async (data) => {
    if (confirm('Are you sure to delete this document?')) {
      if (data.attachment && data.attachment.fullPath) {
        await deleteFileAsync(data.attachment.fullPath);
      }
      await deleteInstrumentDocuments(data);
    }
  };

  const handleDeleteEvent = async (eventDetails) => {
    if (confirm('Are you sure to delete this event?')) {
      await deleteInstrumentEvents(eventDetails);
    }
  };

  const getEventsStatus = () => {
    const today = moment();
    const eventData = events.map((event) => {
      const status = instrumentSettingsEventStatus.find((s) => s.id === event.status);
      return {
        status: status?.name || event.statusName,
        dateDue: moment(event.dateDue.seconds * 1000)
      };
    });
    if (eventData.findIndex((event) => event.status === 'In Progress') !== -1) {
      return 'In Progress';
    } else if (
      eventData.findIndex(
        (event) => event.dateDue.isBefore(today) && event.status !== 'Complete'
      ) !== -1
    ) {
      return 'Past Due';
    }

    return 'Active';
  };

  const handleAddDocuments = async (data) => {
    const { attachment, ...document } = { ...data, instrumentId: id };

    await setInstrumentDocuments(document);

    if (attachment && attachment.length > 0) {
      document.attachment = await uploadFileAsync(
        attachment[0],
        `instrument-management/${id}/${document.id}/${attachment[0].name}`,
        document
      );
    } else {
      document.attachment = attachment;
    }

    await setInstrumentDocuments(document);

    setOpenAddDocument(false);
  };

  const showFormDocument = (data) => {
    setEditingDocument(data);
    setOpenAddDocument(true);
  };

  const handleAddGroup = async (data) => {
    const selectedGroups = Object.keys(data).filter((groupId) => data[groupId]);
    await setInstrumentEquipments({
      ...instrumentDetails,
      groups: [...Array.from(new Set([...(instrumentDetails?.groups || []), ...selectedGroups]))]
    });
    setOpenAddPerson(false);
  };

  const handleDeleteGroup = async (data) => {
    const remainingGroups = instrumentDetails?.groups.filter((group) => group !== data.id);
    await setInstrumentEquipments({
      ...instrumentDetails,
      groups: remainingGroups
    });
  };

  return (
    <>
      {hasPermission(permissionData, SCREEN.INSTRUMENT_INSTRUMENTS, RULES.VIEW) ? (
        <div className="event-details">
          <div className="event-header">
            <h2>Instrument Details</h2>
            <div className="actions">
              {renderMUIButtonWithPermissions(
                'Edit',
                () => setOpen(true),
                SCREEN.INSTRUMENT_INSTRUMENTS,
                RULES.UPDATE,
                permissionData
              )}
              <ModalFormInstrument
                show={isOpen}
                handleClose={() => setOpen(false)}
                handleSave={handleSave}
                data={instrumentDetails}
                allLocations={allLocations}
                vendors={vendors}
                instrumentSettingsInstrumentStatus={instrumentSettingsInstrumentStatus}
                instrumentSettingsInstrumentType={instrumentSettingsInstrumentType}
                instrumentSettingsInstrumentParameter={instrumentSettingsInstrumentParameter}
                personnel={personnel}
              />
              {renderMUIButtonWithPermissions(
                'Delete',
                handleDelete,
                SCREEN.INSTRUMENT_INSTRUMENTS,
                RULES.DELETE,
                permissionData
              )}
            </div>
          </div>
          <div className="event-information">
            <h3>Instrument Information</h3>
            <table>
              <tbody>
                <tr>
                  <td>Instrument Name</td>
                  <td>{instrumentDetails?.name}</td>
                  <td>Location</td>
                  <td>{location?.name || 'Undefined'}</td>
                </tr>
                <tr>
                  <td>Parameter</td>
                  <td>{parameter?.name || 'Undefined'}</td>
                  <td>Status</td>
                  <td>{status?.name || 'Undefined'}</td>
                </tr>
                <tr>
                  <td>Manufacturer</td>
                  <td>{instrumentDetails?.manufacturer}</td>
                  <td>Model</td>
                  <td>{instrumentDetails?.model}</td>
                </tr>
                <tr>
                  <td>Serial Number</td>
                  <td>{instrumentDetails?.serialNumber}</td>
                  <td>Event Status</td>
                  <td>{getEventsStatus()}</td>
                </tr>
                <tr>
                  <td>Internal ID</td>
                  <td>{instrumentDetails?.internalId}</td>
                  <td>Cost</td>
                  <td>{formatCurrency(instrumentDetails?.cost)}</td>
                </tr>
                <tr>
                  <td>Instrument Type</td>
                  <td>{type?.name || 'Undefined'}</td>
                  <td>In Service Date</td>
                  <td>
                    {instrumentDetails?.inServiceDate
                      ? moment(instrumentDetails?.inServiceDate.seconds * 1000).format('MM/DD/YYYY')
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td>Responsible Party</td>
                  <td>{responsibleParty?.name}</td>
                  <td>High Priority Instrument</td>
                  <td>{instrumentDetails?.highPriorityInstrument}</td>
                </tr>
                <tr>
                  <td>Nickname</td>
                  <td>{instrumentDetails?.nickname}</td>
                  <td>Connected Instrument</td>
                  <td>{instrumentDetails?.connectedInstrument ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td>Expected Life (Years)</td>
                  <td>{instrumentDetails?.expectedLifeYears || ''}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <h3>Events</h3>
            <div className="table-box">
              <CustomTable
                data={events
                  .filter((event) => {
                    return (
                      event.status !== 'In progress' ||
                      (event.dateDue.seconds * 1000 >= moment().startOf('day').valueOf() &&
                        event.status !== 'Complete')
                    );
                  })
                  .sort((a, b) => a.dateDue.seconds - b.dateDue.seconds)}
                header={instrumentEventColumns({
                  instrumentName: instrumentDetails?.name,
                  persons: personnel,
                  statuses: instrumentSettingsEventStatus
                })}
                deleteRowData={handleDeleteEvent}
                numberOfRows={10}
                action={
                  hasPermission(permissionData, SCREEN.INSTRUMENT_INSTRUMENTS, RULES.UPDATE)
                    ? ['delete']
                    : ['none']
                }
                sort={{ sorting: { sortModel: [{ field: 'dateDue', sort: 'desc' }] } }}
              />
              <div className="button-below">
                {hasPermission(permissionData, SCREEN.INSTRUMENT_INSTRUMENTS, RULES.UPDATE) ? (
                  <Button
                    variant="contained"
                    component={Link}
                    sx={{ color: 'white !important' }}
                    to={`/instrument/events?addInstrumentEvent=${instrumentDetails?.id}`}
                  >
                    Add event
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <h3>Notes</h3>
            <div>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={instrumentDetails?.notes}
                  readOnly={true}
                />
              </Form.Group>
            </div>
            <h3>Documents</h3>
            <div className="table-box">
              <CustomTable
                data={documents.filter(
                  (document) => document.instrumentId === id && !document.eventId
                )}
                header={documentColumns}
                deleteRowData={handleDeleteDocument}
                viewRowData={(data) => showFormDocument(data)}
                numberOfRows={10}
                action={
                  hasPermission(permissionData, SCREEN.INSTRUMENT_INSTRUMENTS, RULES.UPDATE)
                    ? ['view', 'delete']
                    : ['view']
                }
                sort={{ sorting: { sortModel: [{ field: 'documentId', sort: 'asc' }] } }}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add document',
                  () => showFormDocument(null),
                  SCREEN.INSTRUMENT_INSTRUMENTS,
                  RULES.UPDATE,
                  permissionData
                )}
                <ModalFormDocument
                  show={isOpenAddDocument}
                  handleClose={() => setOpenAddDocument(false)}
                  handleSave={handleAddDocuments}
                  data={editingDocument}
                  disabled={
                    !hasPermission(permissionData, SCREEN.INSTRUMENT_INSTRUMENTS, RULES.UPDATE)
                  }
                  instrumentSettingsDocumentType={instrumentSettingsDocumentType}
                />
              </div>
            </div>

            <h3>Vendors</h3>
            <div className="table-box">
              <CustomTable
                data={vendors
                  .filter(
                    (vendor) =>
                      (instrumentDetails?.vendors || []).includes(vendor.id) ||
                      (events.flatMap((e) => e.vendors) || []).includes(vendor.id)
                  )
                  .map((vendor) => {
                    if (!(instrumentDetails?.vendors || []).includes(vendor.id)) {
                      return { ...vendor, fromEvent: true };
                    }
                    return vendor;
                  })}
                header={vendorColumns(
                  handleDeleteVendor,
                  hasPermission(permissionData, SCREEN.INSTRUMENT_INSTRUMENTS, RULES.UPDATE)
                )}
                numberOfRows={10}
                action={['none']}
                sort={{ sorting: { sortModel: [{ field: 'companyName', sort: 'asc' }] } }}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add vendor',
                  () => setOpenAddVendor(true),
                  SCREEN.INSTRUMENT_INSTRUMENTS,
                  RULES.UPDATE,
                  permissionData
                )}
                <ModalAddVendor
                  show={isOpenAddVendor}
                  handleClose={() => setOpenAddVendor(false)}
                  handleSave={handleAddVendors}
                  vendors={vendors
                    .filter((vendor) => !(instrumentDetails?.vendors || []).includes(vendor.id))
                    .map((vendor) => ({ ...vendor, name: vendor.companyName }))
                    .sort(alphaNumericSorter)}
                />
              </div>
            </div>

            <h3>Notifications</h3>
            <div className="table-box">
              <div className="custom-table">
                <Box display="flex" gap={2} flexDirection="column">
                  {allNotificationGroup
                    .filter((group) => instrumentDetails?.groups?.includes(group.id) || false)
                    .map((row) => {
                      return {
                        ...row,
                        users: (row.users || [])
                          .map((userId) => {
                            const user = personnel.find((item) => item.id === userId);
                            return user || null;
                          })
                          .filter(Boolean)
                      };
                    })
                    .map((group, index) => {
                      return (
                        <Card key={group.id}>
                          <CardHeader
                            sx={{
                              '& .MuiTypography-root': {
                                fontSize: '15px',
                                fontWeight: 700
                              }
                            }}
                            title={group.name}
                            action={
                              <>
                                <IconButton
                                  onClick={() => {
                                    const temp = [...isOpenUser];
                                    temp[index] = !temp[index];
                                    setToggleUser([...temp]);
                                  }}
                                >
                                  {isOpenUser[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                                {hasPermission(
                                  permissionData,
                                  SCREEN.INSTRUMENT_INSTRUMENTS,
                                  RULES.UPDATE
                                ) ? (
                                  <IconButton
                                    onClick={() => {
                                      if (confirm('Are you sure to delete this group?')) {
                                        handleDeleteGroup(group);
                                      }
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                ) : (
                                  <> </>
                                )}
                              </>
                            }
                          />
                          <Collapse in={isOpenUser[index]}>
                            <CardContent>
                              <CustomTable
                                numberOfRows={20}
                                data={group.users || []}
                                header={listNotificationGroupsColumns}
                                sort={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
                                sx={{
                                  '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
                                    display: 'none'
                                  }
                                }}
                              />
                            </CardContent>
                          </Collapse>
                        </Card>
                      );
                    })}
                </Box>
              </div>

              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add group',
                  () => setOpenAddPerson(true),
                  SCREEN.INSTRUMENT_INSTRUMENTS,
                  RULES.UPDATE,
                  permissionData
                )}
                <ModalAddPerson
                  show={isOpenAddPerson}
                  handleClose={() => setOpenAddPerson(false)}
                  handleSave={handleAddGroup}
                  groups={allNotificationGroup.filter(
                    (group) => !instrumentDetails?.groups?.includes(group.id)
                  )}
                />
              </div>
            </div>
            <h3>Status History</h3>
            <div className="table-box">
              <CustomTable
                data={instrumentStatusLogs}
                header={statusHistoryColumns}
                numberOfRows={10}
                action={['none']}
                sort={{ sorting: { sortModel: [{ field: 'createdAt', sort: 'desc' }] } }}
              />
            </div>
            <div className="button-below text-center">
              <br />
              <br />
            </div>
          </div>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
};

export default InstrumentDetails;
