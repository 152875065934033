import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { associatedEventColumns, associatedInstrumentColumns, VendorStatus } from './Columns';
import { useHistory, useParams } from '../../../util/router';
import {
  deleteInstrumentVendors,
  setInstrumentEquipments,
  setInstrumentEvents,
  setInstrumentVendors,
  useGetPersonnel,
  useInstrumentEquipments,
  useInstrumentEvents,
  useInstrumentSettings,
  useInstrumentVendorDetails
} from '../../../util/db';
import ModalFormVendor from '../Modal/ModalFormVendor';
import { SETTING_TYPE } from './DefineCategories';
import { useAuth } from '../../../util/auth';
import { RULES, SCREEN } from '../../../util/Constant';
import { renderMUIButtonWithPermissions } from '../../../util/util';
import { hasPermission } from '../utils';

const VendorDetails = () => {
  const auth = useAuth();
  const history = useHistory();
  const { id } = useParams();
  const { data: rawVendorDetails } = useInstrumentVendorDetails(id);
  const { data: instruments = [] } = useInstrumentEquipments({ vendorId: id });
  const { data: allInstruments = [] } = useInstrumentEquipments();
  const { data: events = [] } = useInstrumentEvents({ vendorId: id });
  const { data: instrumentSettingsInstrumentStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );
  const { data: instrumentSettingsEventStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: instrumentSettingsDocumentType = [] } = useInstrumentSettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const { data: instrumentSettingsVendorType = [] } = useInstrumentSettings(
    SETTING_TYPE.VENDOR_TYPE
  );
  const { data: personnel = [] } = useGetPersonnel();
  const [isOpenAddDocument, setOpenAddDocument] = useState(false);
  const [vendorDetails, setVendorDetails] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  useEffect(() => {
    if (rawVendorDetails && rawVendorDetails.length) {
      setVendorDetails(rawVendorDetails[[0]]);
    }
  }, [rawVendorDetails]);

  const handleSave = async (data) => {
    await setInstrumentVendors(data);
    setOpen(false);
  };

  const handleDelete = async () => {
    if (confirm('Are you sure to delete this vendor?')) {
      await deleteInstrumentVendors(vendorDetails);
      history.push('/instrument/settings/vendors');
    }
  };

  const handleDeleteAssociatedInstrument = async (instrument) => {
    const { fromInstrument, ...instrumentDetails } = instrument;

    const remainingVendors = (instrumentDetails.vendors || []).filter(
      (item) => item !== vendorDetails.id
    );

    if (confirm('Are you sure to delete this instrument?')) {
      await setInstrumentEquipments({ ...instrumentDetails, vendors: remainingVendors });
    }
  };

  const handleDeleteAssociatedEvent = async (eventDetails) => {
    const remainingVendors = (eventDetails.vendors || []).filter(
      (item) => item !== vendorDetails.id
    );
    if (confirm('Are you sure to delete this event?')) {
      await setInstrumentEvents({ ...eventDetails, vendors: remainingVendors });
    }
  };

  const handleDeleteDocument = async (data) => {
    if (confirm('Are you sure to delete this document?')) {
      if (data.attachment && data.attachment.fullPath) {
        await deleteFileAsync(data.attachment.fullPath);
      }
      await deleteInstrumentDocuments(data);
    }
  };

  const handleAddDocuments = async (data) => {
    const { attachment, ...document } = { ...data, vendorId: id };

    await setInstrumentDocuments(document);

    // Is file object => upload
    if (attachment && attachment.length > 0) {
      document.attachment = await uploadFileAsync(
        attachment[0],
        `instrument-management/${id}/${document.id}/${attachment[0].name}`,
        document
      );
    } else {
      document.attachment = attachment;
    }

    await setInstrumentDocuments(document);

    setOpenAddDocument(false);
  };

  const showFormDocument = (data) => {
    setEditingDocument(data);
    setOpenAddDocument(true);
  };

  const vendorType = instrumentSettingsVendorType.find(
    (type) => type.id === vendorDetails?.vendorType
  );

  return (
    <>
      {hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.VIEW) ? (
        <div className="event-details">
          <div className="event-header">
            <h2></h2>
            <div className="actions">
              {renderMUIButtonWithPermissions(
                'Edit',
                () => setOpen(true),
                SCREEN.INSTRUMENT_SETTINGS,
                RULES.UPDATE,
                permissionData
              )}
              <ModalFormVendor
                show={isOpen}
                data={vendorDetails}
                handleClose={() => setOpen(false)}
                handleSave={handleSave}
                instrumentSettingsVendorType={instrumentSettingsVendorType}
              />
              {renderMUIButtonWithPermissions(
                'Delete',
                handleDelete,
                SCREEN.INSTRUMENT_SETTINGS,
                RULES.DELETE,
                permissionData
              )}
            </div>
          </div>
          <div className="event-information">
            <h3>Vendor Information</h3>
            <table>
              <tbody>
                <tr>
                  <td>Vendor ID</td>
                  <td>{vendorDetails?.vendorId}</td>
                  <td>Primary Contact Name</td>
                  <td>{vendorDetails?.contactName}</td>
                </tr>
                <tr>
                  <td>Company</td>
                  <td>{vendorDetails?.companyName}</td>
                  <td>Primary Email</td>
                  <td>{vendorDetails?.contactEmail}</td>
                </tr>
                <tr>
                  <td>Department</td>
                  <td>{vendorDetails?.department}</td>
                  <td>Primary Phone</td>
                  <td>{vendorDetails?.contactPhone}</td>
                </tr>
                <tr>
                  <td>Vendor Type</td>
                  <td>{vendorType?.name}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Vendor Status</td>
                  <td>{VendorStatus[vendorDetails?.status]}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <h3>Notes</h3>
            <div>
              <Form.Group className="mb-3">
                <Form.Control as="textarea" rows={3} value={vendorDetails?.notes} readOnly={true} />
              </Form.Group>
            </div>

            <h3>Associated instruments</h3>
            <div className="table-box">
              <CustomTable
                data={allInstruments
                  .filter(
                    (i) =>
                      (i.vendors || []).includes(vendorDetails?.id) ||
                      events.map((e) => e.instrumentName).includes(i.id)
                  )
                  .map((i) => {
                    if ((i.vendors || []).includes(vendorDetails?.id)) {
                      return { ...i, fromInstrument: true };
                    }
                    return i;
                  })}
                header={associatedInstrumentColumns({
                  instrumentStatus: instrumentSettingsInstrumentStatus,
                  handleDeleteAssociatedInstrument,
                  hasPermission: hasPermission(
                    permissionData,
                    SCREEN.INSTRUMENT_SETTINGS,
                    RULES.UPDATE
                  )
                })}
                numberOfRows={10}
                action={['none']}
              />
            </div>

            <h3>Associated events</h3>
            <div className="table-box">
              <CustomTable
                data={events}
                header={associatedEventColumns({
                  instruments: allInstruments,
                  eventStatus: instrumentSettingsEventStatus
                })}
                deleteRowData={handleDeleteAssociatedEvent}
                numberOfRows={10}
                action={
                  hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.UPDATE)
                    ? ['delete']
                    : ['none']
                }
              />
            </div>

            <div className="button-below text-center">
              <br />
              <br />
            </div>
          </div>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
};

export default VendorDetails;
