import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  getMetadata,
  updateMetadata
} from 'firebase/storage';
import firebaseApp from './firebase';

export const storage = getStorage(firebaseApp);

export const deleteFileAsync = async (fullPath) => {
  const storageRef = ref(storage, fullPath);

  await deleteObject(storageRef);
};

export const uploadFileAsync = async (file, filePath, customMetadata) => {
  const storageRef = ref(storage, filePath);

  const result = await uploadBytes(
    storageRef,
    file,
    customMetadata ? { customMetadata: customMetadata } : {}
  );
  const downloadUrl = await getDownloadURL(result.ref);

  return {
    fileName: file.name,
    filePath: filePath,
    downloadUrl: downloadUrl
  };
};

export const updateMetadataAsync = async (fullPath, customMetadata) => {
  const metadata = {
    customMetadata: customMetadata
  };

  const storageRef = ref(storage, fullPath);

  await updateMetadata(storageRef, metadata);
};

export const getMetadataAsync = async (fullPath) => {
  const storageRef = ref(storage, fullPath);

  return await getMetadata(storageRef);
};
