import React, { useEffect, useState } from 'react';
import CustomTable from '../../Table/CustomTable';
import { eventColumns } from './Columns';
import {
  useGetPersonnel,
  useInstrumentEquipments,
  useInstrumentEvents,
  useInstrumentSettings
} from '../../../util/db';
import { SETTING_TYPE } from '../TabSettings/DefineCategories';
import moment from 'moment';
import { Col, Form, Row } from 'react-bootstrap';
import { useAuth } from '../../../util/auth';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';

const EventAtGlance = () => {
  const auth = useAuth();
  const { data: rawEvents = [] } = useInstrumentEvents();
  const { data: instruments = [] } = useInstrumentEquipments();
  const { data: personnel = [] } = useGetPersonnel();
  const { data: instrumentSettingsEventStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: allLocations = [] } = useInstrumentSettings(SETTING_TYPE.INSTRUMENT_LOCATION);
  const { data: instrumentSettingsEventType = [] } = useInstrumentSettings(SETTING_TYPE.EVENT_TYPE);
  const [instrumentFilters, setInstrumentFilters] = useState();
  const [responsibleParty, setResponsibleParty] = useState();
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  const handleLocationChange = (event) => {
    if (event.target.value) {
      const instrumentIds = instruments
        .filter((i) => i.location == event.target.value)
        .map((i) => i.id);
      setInstrumentFilters(instrumentIds);
    } else {
      setInstrumentFilters(undefined);
    }
  };

  const now = moment();
  const events = rawEvents
    .map((row) => {
      const dataInstrument = instruments.find((item) => item.id === row.instrumentName);
      const dataStatus = instrumentSettingsEventStatus.find((item) => item.id === row.status);
      const dataType = instrumentSettingsEventType.find((item) => item.id === row.eventType);
      const responsibleParty = personnel.find((item) => item.id === row.responsibleParty);
      return {
        ...row,
        location: dataInstrument?.location,
        instrumentId: dataInstrument?.id,
        instrumentName: dataInstrument?.name,
        status: dataStatus?.name,
        eventType: dataType?.name,
        responsiblePartyName: responsibleParty?.name
      };
    })
    .filter((row) => {
      if (!instrumentFilters) {
        return true;
      }
      return instrumentFilters.includes(row.instrumentId);
    })
    .filter((row) => {
      if (!responsibleParty) {
        return true;
      }
      return row.responsibleParty === responsibleParty;
    });
  const pastDueRows = events.filter((event) => {
    return (
      event.dateDue.seconds * 1000 < now.startOf('day').valueOf() &&
      event.status !== 'Complete' &&
      event.status !== 'In progress'
    );
  });
  const inProgressRows = events.filter((event) => event.status === 'In progress');
  const next30DaysRows = events.filter((event) => {
    return (
      event.dateDue.seconds * 1000 >= now.startOf('day').valueOf() &&
      event.dateDue.seconds * 1000 <= moment().add(30, 'days').endOf('day').valueOf() &&
      event.status !== 'Complete' &&
      event.status !== 'In progress'
    );
  });
  return (
    <>
      {hasPermission(permissionData, SCREEN.INSTRUMENT_EVENTS, RULES.VIEW) ? (
        <>
          <h2>At a glance</h2>
          <div className="grid-view">
            <div className="filter">
              <p>
                <strong>Filter data by:</strong>
              </p>
              <div>
                <Row>
                  <Form.Group as={Col} controlId="location">
                    <Form.Label>Location</Form.Label>
                    <Form.Select onChange={(event) => handleLocationChange(event)}>
                      <option value="">All locations</option>
                      {allLocations.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Group as={Col}>
                      <Form.Label>Responsible Party</Form.Label>
                      <Form.Select onChange={(event) => setResponsibleParty(event.target.value)}>
                        <option value="">All Responsible Party</option>
                        {personnel.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Form.Group>
                  <Form.Group as={Col}></Form.Group>
                  <Form.Group as={Col}></Form.Group>
                </Row>
              </div>
            </div>
            <h3>Past Due</h3>
            <CustomTable numberOfRows={20} data={pastDueRows} header={eventColumns} />
            <h3>In Progress</h3>
            <CustomTable numberOfRows={20} data={inProgressRows} header={eventColumns} />
            <h3>Next 30 days</h3>
            <CustomTable numberOfRows={20} data={next30DaysRows} header={eventColumns} />
          </div>
        </>
      ) : (
        <> </>
      )}
    </>
  );
};

export default EventAtGlance;
