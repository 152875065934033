import { Box, Button, CardContent, CardHeader, Collapse, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { documentColumns, notificationColumns, vendorColumns } from './Columns';
import ModalAddVendor from '../Modal/ModalAddVendor';
import ModalAddPerson from '../Modal/ModalAddPerson';
import { Link, useHistory, useParams } from '../../../util/router';
import {
  deleteInstrumentDocuments,
  deleteInstrumentEvents,
  setInstrumentDocuments,
  setInstrumentEvents,
  useGetPersonnel,
  useInstrumentDocuments,
  useInstrumentEquipments,
  useInstrumentEventDetails,
  useInstrumentEvents,
  useInstrumentNotificationGroups,
  useInstrumentNotifications,
  useInstrumentSettings,
  useInstrumentVendors
} from '../../../util/db';
import { SETTING_TYPE } from '../TabSettings/DefineCategories';
import moment from 'moment';
import {
  alphaNumericSorter,
  formatCurrency,
  renderMUIButtonWithPermissions
} from '../../../util/util';
import ModalFormEvent from '../Modal/ModalFormEvent';
import ModalFormDocument from '../Modal/ModalFormDocument';
import { uploadFileAsync } from '../../../util/storage';
import { Delete, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { listNotificationGroupsColumns } from '../TabSettings/Columns';
import { all } from 'axios';
import { useAuth } from '../../../util/auth';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';

const EventDetails = () => {
  const auth = useAuth();
  const history = useHistory();
  const { id } = useParams();
  const { data: personnel = [] } = useGetPersonnel();
  const { data: rawEventDetails = [] } = useInstrumentEventDetails(id);
  const { data: instruments = [] } = useInstrumentEquipments();
  const { data: vendors = [] } = useInstrumentVendors();
  const { data: documents = [] } = useInstrumentDocuments();
  const { data: instrumentSettingsEventStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: instrumentSettingsDocumentType = [] } = useInstrumentSettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const { data: allLocations = [] } = useInstrumentSettings(SETTING_TYPE.INSTRUMENT_LOCATION);

  const { data: instrumentSettingsEventType = [] } = useInstrumentSettings(SETTING_TYPE.EVENT_TYPE);
  const { data: allNotificationGroup = [] } = useInstrumentNotificationGroups();

  const [isOpen, setOpen] = useState(false);
  const [isOpenAddDocument, setOpenAddDocument] = useState(false);
  const [isOpenAddVendor, setOpenAddVendor] = useState(false);
  const [isOpenAddPerson, setOpenAddPerson] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [editingDocument, setEditingDocument] = useState(null);
  const [isOpenUser, setToggleUser] = useState([]);
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  useEffect(() => {
    if (rawEventDetails && rawEventDetails.length) {
      setEventDetails(rawEventDetails[0]);
    }
  }, [rawEventDetails]);

  const handleSave = async (data) => {
    const dataStatus = instrumentSettingsEventStatus.find((item) => item.id === data.status);

    createRecurringEvent(
      await setInstrumentEvents({ ...eventDetails, ...data, statusName: dataStatus?.name || '' })
    );
    setOpen(false);
  };

  const handleDelete = async () => {
    if (confirm('Are you sure to delete this event?')) {
      await deleteInstrumentEvents(eventDetails);
      history.push('/instrument/events');
    }
  };

  const handleMarkAsComplete = async () => {
    if (confirm('Are you sure to mark as complete this event?')) {
      const completeStatus = instrumentSettingsEventStatus.find(
        (status) => status.name === 'Complete'
      );

      if (completeStatus) {
        createRecurringEvent(
          await setInstrumentEvents({
            ...eventDetails,
            completeDate: new Date(),
            status: completeStatus.id,
            statusName: 'Complete'
          })
        );
      }
    }
  };
  const handleMarkAsInComplete = async () => {
    if (confirm('Are you sure to mark as incomplete this event?')) {
      await setInstrumentEvents({ ...eventDetails, status: '', statusName: '' });
    }
  };

  const createRecurringEvent = async ({
    id,
    eventNumber,
    status,
    statusName,
    completeDate,
    ...restEvent
  }) => {
    if (restEvent.recurringEvent === 'Yes') {
      const eventStatus = instrumentSettingsEventStatus.find((item) => item.id === status);
      if (eventStatus.name === 'Complete') {
        let [recurNum, recurUnit] = String(restEvent.recurringInterval).split('|');
        if (!recurNum) {
          recurNum = restEvent.recurringIn;
          recurUnit = 'days';
        }
        await setInstrumentEvents({
          ...restEvent,
          parentEventId: id,
          dateDue: moment()
            .add(Number(recurNum || 0), recurUnit || 'days')
            .toDate()
        });
      }
    }
  };

  const handleAddVendors = async (data) => {
    const selectedVendors = Object.keys(data).filter((vendorId) => data[vendorId]);
    await setInstrumentEvents({
      ...eventDetails,
      vendors: [...(eventDetails.vendors || []), ...selectedVendors]
    });
    setOpenAddVendor(false);
  };

  const handleDeleteVendor = async (data) => {
    const remainingVendors = eventDetails.vendors.filter((vendor) => vendor !== data.id);
    await setInstrumentEvents({
      ...eventDetails,
      vendors: remainingVendors
    });
  };

  const handleDeleteDocument = async (data) => {
    if (confirm('Are you sure to delete this document?')) {
      if (data.attachment && data.attachment.fullPath) {
        await deleteFileAsync(data.attachment.fullPath);
      }
      await deleteInstrumentDocuments(data);
    }
  };

  const handleAddGroup = async (data) => {
    const selectedGroups = Object.keys(data).filter((groupId) => data[groupId]);
    await setInstrumentEvents({
      ...eventDetails,
      groups: [...Array.from(new Set([...(eventDetails?.groups || []), ...selectedGroups]))]
    });
    setOpenAddPerson(false);
  };

  const handleDeleteGroup = async (data) => {
    const remainingGroups = eventDetails.groups.filter((group) => group !== data.id);
    await setInstrumentEvents({
      ...eventDetails,
      groups: remainingGroups
    });
  };

  const handleCloneEvent = async () => {
    const { id, eventNumber, ...payload } = eventDetails;
    const data = await setInstrumentEvents({
      ...payload,
      eventNumber: `Clone from ${eventNumber}`
    });
    history.push(`/instrument/events/${data.id}`);
  };

  const handleAddDocuments = async (data) => {
    const { attachment, ...document } = {
      ...data,
      eventId: eventDetails.id,
      instrumentId: instrument.id
    };

    await setInstrumentDocuments(document);

    if (attachment && attachment.length > 0) {
      document.attachment = await uploadFileAsync(
        attachment[0],
        `instrument-management/${instrument.id}/${document.id}/${attachment[0].name}`,
        document
      );
    } else {
      document.attachment = attachment;
    }

    await setInstrumentDocuments(document);

    setOpenAddDocument(false);
  };

  const showFormDocument = (data) => {
    setEditingDocument(data);
    setOpenAddDocument(true);
  };

  const status = instrumentSettingsEventStatus.find((item) => item.id === eventDetails?.status);
  const eventType = instrumentSettingsEventType.find((item) => item.id === eventDetails?.eventType);
  const instrument = instruments.find((item) => item.id === eventDetails?.instrumentName);
  const responsibleParty = personnel.find((item) => item.id === eventDetails?.responsibleParty);
  const location = allLocations.find((item) => item.id === instrument?.location);
  const [recurNum, recurUnit] = String(eventDetails?.recurringInterval).split('|');
  const recurringInterval = recurNum
    ? `${recurNum} ${recurUnit}`
    : `${eventDetails?.recurringIn} days`;

  return (
    <>
      {hasPermission(permissionData, SCREEN.INSTRUMENT_EVENTS, RULES.VIEW) ? (
        <div className="event-details">
          <div className="event-header">
            <h2>Event Details</h2>
            <div className="actions">
              {(status?.name || eventDetails?.statusName) !== 'Complete'
                ? renderMUIButtonWithPermissions(
                    'Mark as complete',
                    handleMarkAsComplete,
                    SCREEN.INSTRUMENT_EVENTS,
                    RULES.UPDATE,
                    permissionData
                  )
                : renderMUIButtonWithPermissions(
                    'Mark as incomplete',
                    handleMarkAsInComplete,
                    SCREEN.INSTRUMENT_EVENTS,
                    RULES.UPDATE,
                    permissionData
                  )}
              {renderMUIButtonWithPermissions(
                'Edit',
                () => setOpen(true),
                SCREEN.INSTRUMENT_EVENTS,
                RULES.UPDATE,
                permissionData
              )}
              <ModalFormEvent
                show={isOpen}
                handleClose={() => setOpen(false)}
                handleSave={handleSave}
                data={eventDetails}
                personnel={personnel}
                instrumentSettingsEventStatus={instrumentSettingsEventStatus}
                instrumentSettingsEventType={instrumentSettingsEventType}
                instruments={instruments}
                allLocations={allLocations}
              />
              {renderMUIButtonWithPermissions(
                'Clone',
                handleCloneEvent,
                SCREEN.INSTRUMENT_EVENTS,
                RULES.UPDATE,
                permissionData
              )}
              {renderMUIButtonWithPermissions(
                'Delete',
                handleDelete,
                SCREEN.INSTRUMENT_EVENTS,
                RULES.DELETE,
                permissionData
              )}
            </div>
          </div>
          <div className="event-information">
            <h3>Event Information</h3>
            <table>
              <tbody>
                <tr>
                  <td>Event Number</td>
                  <td>{eventDetails?.eventNumber}</td>
                  <td>Date Due</td>
                  <td>
                    {eventDetails?.dateDue
                      ? moment(eventDetails?.dateDue.seconds * 1000).format('MM/DD/YYYY')
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{eventDetails?.description}</td>

                  <td>Created On</td>
                  <td>
                    {eventDetails?.createdAt
                      ? moment(eventDetails?.createdAt.seconds * 1000).format('MM/DD/YYYY')
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{location?.name}</td>
                  <td>Status</td>
                  <td>{status?.name || eventDetails?.statusName}</td>
                </tr>
                <tr>
                  <td>Instrument Name</td>
                  <td>
                    <Link to={`/instrument/detail/${instrument?.id}`}>{instrument?.name}</Link>
                  </td>
                  <td>Event Type</td>
                  <td>{eventType?.name}</td>
                </tr>
                <tr>
                  <td>Responsible Party (SPA)</td>
                  <td>{responsibleParty?.name}</td>
                  <td>Recurring Event</td>
                  <td>{eventDetails?.recurringEvent}</td>
                </tr>
                <tr>
                  <td>Cost</td>
                  <td>{formatCurrency(eventDetails?.cost)}</td>
                  <td>Recurring Interval</td>
                  <td>{eventDetails?.recurringEvent === 'Yes' ? recurringInterval : ''}</td>
                </tr>
                <tr>
                  <td>Complete Date</td>
                  <td>
                    {eventDetails?.completeDate
                      ? moment(eventDetails?.completeDate.seconds * 1000).format('MM/DD/YYYY')
                      : ''}
                  </td>
                  <td>Notification Lead Time (days)</td>
                  <td>
                    {!isNaN(eventDetails?.notificationLeadTime)
                      ? eventDetails?.notificationLeadTime
                      : ''}
                  </td>
                </tr>
              </tbody>
            </table>
            <h3>Notes</h3>
            <div>
              <Form.Group className="mb-3">
                <Form.Control as="textarea" rows={3} value={eventDetails?.notes} readOnly={true} />
              </Form.Group>
            </div>
            <h3>Documents</h3>
            <div className="table-box">
              <CustomTable
                data={documents.filter(
                  (document) =>
                    document.eventId === eventDetails?.id ||
                    (document.instrumentId === instrument?.id && !document.eventId)
                )}
                header={documentColumns(
                  showFormDocument,
                  handleDeleteDocument,
                  hasPermission(permissionData, SCREEN.INSTRUMENT_EVENTS, RULES.UPDATE)
                )}
                deleteRowData={handleDeleteDocument}
                viewRowData={(data) => showFormDocument(data)}
                numberOfRows={10}
                action={['none']}
                sort={{ sorting: { sortModel: [{ field: 'documentId', sort: 'asc' }] } }}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add document',
                  () => showFormDocument(null),
                  SCREEN.INSTRUMENT_EVENTS,
                  RULES.UPDATE,
                  permissionData
                )}
                <ModalFormDocument
                  show={isOpenAddDocument}
                  handleClose={() => setOpenAddDocument(false)}
                  handleSave={handleAddDocuments}
                  data={editingDocument}
                  disabled={
                    !hasPermission(permissionData, SCREEN.INSTRUMENT_EVENTS, RULES.UPDATE) ||
                    (editingDocument && !editingDocument?.eventId)
                  }
                  instrumentSettingsDocumentType={instrumentSettingsDocumentType}
                />
              </div>
            </div>

            <h3>Vendors</h3>
            <div className="table-box">
              <CustomTable
                data={vendors.filter((vendor) => (eventDetails?.vendors || []).includes(vendor.id))}
                header={vendorColumns}
                deleteRowData={handleDeleteVendor}
                numberOfRows={10}
                action={
                  hasPermission(permissionData, SCREEN.INSTRUMENT_EVENTS, RULES.UPDATE)
                    ? ['delete']
                    : ['none']
                }
                sort={{ sorting: { sortModel: [{ field: 'companyName', sort: 'asc' }] } }}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add vendor',
                  () => setOpenAddVendor(true),
                  SCREEN.INSTRUMENT_EVENTS,
                  RULES.UPDATE,
                  permissionData
                )}
                <ModalAddVendor
                  show={isOpenAddVendor}
                  handleClose={() => setOpenAddVendor(false)}
                  handleSave={handleAddVendors}
                  vendors={vendors
                    .filter((vendor) => !(eventDetails?.vendors || []).includes(vendor.id))
                    .map((vendor) => ({ ...vendor, name: vendor.companyName }))
                    .sort(alphaNumericSorter)}
                />
              </div>
            </div>

            <h3>Notifications</h3>
            <div className="table-box">
              <div className="custom-table">
                <Box display="flex" gap={2} flexDirection="column">
                  {allNotificationGroup
                    .filter((group) => eventDetails?.groups?.includes(group?.id) || false)
                    .map((row) => {
                      return {
                        ...row,
                        users: (row.users || [])
                          .map((userId) => {
                            const user = personnel.find((item) => item.id === userId);
                            return user || null;
                          })
                          .filter(Boolean)
                      };
                    })
                    .map((group, index) => {
                      return (
                        <Card key={group?.id}>
                          <CardHeader
                            sx={{
                              '& .MuiTypography-root': {
                                fontSize: '15px',
                                fontWeight: 700
                              }
                            }}
                            title={group.name}
                            action={
                              <>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    const temp = [...isOpenUser];
                                    temp[index] = !temp[index];
                                    setToggleUser([...temp]);
                                  }}
                                >
                                  {isOpenUser[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                                {hasPermission(
                                  permissionData,
                                  SCREEN.INSTRUMENT_EVENTS,
                                  RULES.UPDATE
                                ) ? (
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      if (confirm('Are you sure to delete this group?')) {
                                        handleDeleteGroup(group);
                                      }
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                ) : (
                                  <> </>
                                )}
                              </>
                            }
                          />
                          <Collapse in={isOpenUser[index]}>
                            <CardContent>
                              <CustomTable
                                numberOfRows={20}
                                data={group.users || []}
                                header={listNotificationGroupsColumns}
                                sort={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
                                sx={{
                                  '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
                                    display: 'none'
                                  }
                                }}
                              />
                            </CardContent>
                          </Collapse>
                        </Card>
                      );
                    })}
                </Box>
              </div>

              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add group',
                  () => setOpenAddPerson(true),
                  SCREEN.INSTRUMENT_EVENTS,
                  RULES.UPDATE,
                  permissionData
                )}
                <ModalAddPerson
                  show={isOpenAddPerson}
                  handleClose={() => setOpenAddPerson(false)}
                  handleSave={handleAddGroup}
                  groups={allNotificationGroup.filter(
                    (group) => !eventDetails?.groups?.includes(group.id)
                  )}
                />
              </div>
            </div>
            <div className="button-below text-center">
              <br />
              <br />
            </div>
          </div>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
};

export default EventDetails;
