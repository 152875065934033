import React, { useEffect, useState } from 'react';
import CustomTable from '../../Table/CustomTable';
import ModalAddCategory from '../Modal/ModalAddCategory';
import {
  documentTypeColumns,
  eventStatusColumns,
  eventTypeColumns,
  instrumentLocationColumns,
  instrumentParameterColumns,
  instrumentStatusColumns,
  instrumentTypeColumns,
  vendorTypeColumns
} from './Columns';
import { Button } from '@mui/material';
import {
  deleteInstrumentSettings,
  setInstrumentSettings,
  useInstrumentSettings,
  useEventNumberSettings,
  useGetCompanies,
  useGetPersonnel
} from '../../../util/db';
import { Col, Form, Row } from 'react-bootstrap';
import { forEach } from 'lodash';
import { useAuth } from '../../../util/auth';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';
import { renderMUIButtonWithPermissions } from '../../../util/util';

export const SETTING_TYPE = {
  INSTRUMENT_STATUS: 'INSTRUMENT_STATUS',
  INSTRUMENT_TYPE: 'INSTRUMENT_TYPE',
  INSTRUMENT_PARAMETER: 'INSTRUMENT_PARAMETER',
  EVENT_STATUS: 'EVENT_STATUS',
  EVENT_TYPE: 'EVENT_TYPE',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE',
  VENDOR_TYPE: 'VENDOR_TYPE',
  INSTRUMENT_LOCATION: 'INSTRUMENT_LOCATION'
};

export const MODAL_TITLE = {
  INSTRUMENT_STATUS: 'Instrument Status',
  INSTRUMENT_TYPE: 'Instrument Type',
  INSTRUMENT_PARAMETER: 'Instrument Parameter',
  EVENT_STATUS: 'Event Status',
  EVENT_TYPE: 'Event Type',
  DOCUMENT_TYPE: 'Document Type',
  VENDOR_TYPE: 'Vendor Type',
  INSTRUMENT_LOCATION: 'Location'
};

export const DOCUMENT_LOG_TYPE = {
  INSTRUMENT: 'INSTRUMENT',
  EVENT: 'EVENT'
};

const DefineCategories = () => {
  const auth = useAuth();
  const { data: instrumentSettingsInstrumentStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );
  const { data: instrumentSettingsInstrumentType = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_TYPE
  );
  const { data: instrumentSettingsInstrumentParameter = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_PARAMETER
  );
  const { data: instrumentSettingsEventStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: instrumentSettingsEventType = [] } = useInstrumentSettings(SETTING_TYPE.EVENT_TYPE);
  const { data: instrumentSettingsDocumentType = [] } = useInstrumentSettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const { data: instrumentSettingsVendorType = [] } = useInstrumentSettings(
    SETTING_TYPE.VENDOR_TYPE
  );

  const { data: instrumentSettingsLocation = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_LOCATION
  );
  const { data: personnel = [] } = useGetPersonnel();
  const { data: companies } = useGetCompanies();
  const [isOpenAddCategory, setOpenAddCategory] = useState(false);
  const [settingType, setSettingType] = useState('');
  const [title, setTitle] = useState('');
  const [permissionData, setPermissionData] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [tableActions, setTableActions] = useState([]);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) {
          setPermissionData(auth.permissionData[matchingUser.permission]);
        }
      }
    }
  }, [personnel]);

  useEffect(() => {
    let actions = [];
    if (hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.DELETE)) {
      actions = [...actions, 'delete'];
    }
    if (hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.UPDATE)) {
      actions = [...actions, 'view'];
    }
    setTableActions(actions.length === 0 ? ['none'] : actions);
  }, permissionData);

  useEffect(() => {
    const initLocationData = async () => {
      const currentLocationSetting = instrumentSettingsLocation.map((location) => location.name);
      for (let index = 0; index < companies.length; index++) {
        const company = companies[index];
        const currentLocation = `${company.region} - ${company.area} - ${company.laboratory}`;

        if (!currentLocationSetting.includes(currentLocation)) {
          await setInstrumentSettings({
            name: currentLocation,
            type: SETTING_TYPE.INSTRUMENT_LOCATION,
            fromMasterData: true
          });
        }
      }
    };

    if (Array.isArray(companies) && companies.length > 0) {
      initLocationData();
    }
  }, [companies, instrumentSettingsLocation]);

  const handleOpenCategoryModal = (type, data) => {
    setTitle(`${data ? 'Edit' : 'Add'} ${MODAL_TITLE[type]}`);
    setSettingType(type);
    setEditingCategory(data);
    setOpenAddCategory(true);
  };

  const handleSave = async (data) => {
    await setInstrumentSettings({ ...data, type: settingType });
    setOpenAddCategory(false);
  };

  const handleDelete = async (data) => {
    if (confirm('Are you sure you want to delete?')) {
      await deleteInstrumentSettings(data);
    }
  };

  return (
    <>
      {hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.VIEW) ? (
        <>
          <div>
            <h2>User defined categories</h2>
          </div>
          <div className="define-categories">
            <div className="table-box">
              <CustomTable
                data={instrumentSettingsInstrumentStatus}
                header={instrumentStatusColumns}
                deleteRowData={handleDelete}
                viewRowData={(data) =>
                  handleOpenCategoryModal(SETTING_TYPE.INSTRUMENT_STATUS, data)
                }
                numberOfRows={5}
                action={tableActions}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add status',
                  () => handleOpenCategoryModal(SETTING_TYPE.INSTRUMENT_STATUS),
                  SCREEN.INSTRUMENT_SETTINGS,
                  RULES.CREATE,
                  permissionData
                )}
              </div>
            </div>
            <div className="table-box">
              <CustomTable
                data={instrumentSettingsInstrumentType}
                header={instrumentTypeColumns}
                deleteRowData={handleDelete}
                viewRowData={(data) => handleOpenCategoryModal(SETTING_TYPE.INSTRUMENT_TYPE, data)}
                numberOfRows={5}
                action={tableActions}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add type',
                  () => handleOpenCategoryModal(SETTING_TYPE.INSTRUMENT_TYPE),
                  SCREEN.INSTRUMENT_SETTINGS,
                  RULES.CREATE,
                  permissionData
                )}
              </div>
            </div>
            <div className="table-box">
              <CustomTable
                data={instrumentSettingsInstrumentParameter}
                header={instrumentParameterColumns}
                deleteRowData={handleDelete}
                viewRowData={(data) =>
                  handleOpenCategoryModal(SETTING_TYPE.INSTRUMENT_PARAMETER, data)
                }
                numberOfRows={5}
                action={tableActions}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add Parameter',
                  () => handleOpenCategoryModal(SETTING_TYPE.INSTRUMENT_PARAMETER),
                  SCREEN.INSTRUMENT_SETTINGS,
                  RULES.CREATE,
                  permissionData
                )}
              </div>
            </div>
            <div className="table-box">
              <CustomTable
                data={instrumentSettingsEventStatus}
                header={eventStatusColumns}
                deleteRowData={handleDelete}
                viewRowData={(data) => handleOpenCategoryModal(SETTING_TYPE.EVENT_STATUS, data)}
                numberOfRows={5}
                action={tableActions}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add status',
                  () => handleOpenCategoryModal(SETTING_TYPE.EVENT_STATUS),
                  SCREEN.INSTRUMENT_SETTINGS,
                  RULES.CREATE,
                  permissionData
                )}
              </div>
            </div>

            <div className="table-box">
              <CustomTable
                data={instrumentSettingsEventType}
                header={eventTypeColumns}
                deleteRowData={handleDelete}
                viewRowData={(data) => handleOpenCategoryModal(SETTING_TYPE.EVENT_TYPE, data)}
                numberOfRows={5}
                action={tableActions}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add type',
                  () => handleOpenCategoryModal(SETTING_TYPE.EVENT_TYPE),
                  SCREEN.INSTRUMENT_SETTINGS,
                  RULES.CREATE,
                  permissionData
                )}
              </div>
            </div>

            <div className="table-box">
              <CustomTable
                data={instrumentSettingsDocumentType}
                header={documentTypeColumns}
                deleteRowData={handleDelete}
                viewRowData={(data) => handleOpenCategoryModal(SETTING_TYPE.DOCUMENT_TYPE, data)}
                numberOfRows={5}
                action={tableActions}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add type',
                  () => handleOpenCategoryModal(SETTING_TYPE.DOCUMENT_TYPE),
                  SCREEN.INSTRUMENT_SETTINGS,
                  RULES.CREATE,
                  permissionData
                )}
              </div>
            </div>

            <div className="table-box">
              <CustomTable
                data={instrumentSettingsVendorType}
                header={vendorTypeColumns}
                deleteRowData={handleDelete}
                viewRowData={(data) => handleOpenCategoryModal(SETTING_TYPE.VENDOR_TYPE, data)}
                numberOfRows={5}
                action={tableActions}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add type',
                  () => handleOpenCategoryModal(SETTING_TYPE.VENDOR_TYPE),
                  SCREEN.INSTRUMENT_SETTINGS,
                  RULES.CREATE,
                  permissionData
                )}
              </div>
            </div>

            <div className="table-box">
              <CustomTable
                data={instrumentSettingsLocation}
                header={instrumentLocationColumns}
                deleteRowData={handleDelete}
                viewRowData={(data) =>
                  handleOpenCategoryModal(SETTING_TYPE.INSTRUMENT_LOCATION, data)
                }
                numberOfRows={5}
                action={tableActions}
              />
              <div className="button-below">
                {renderMUIButtonWithPermissions(
                  'Add Location',
                  () => handleOpenCategoryModal(SETTING_TYPE.INSTRUMENT_LOCATION),
                  SCREEN.INSTRUMENT_SETTINGS,
                  RULES.CREATE,
                  permissionData
                )}
              </div>
            </div>

            <ModalAddCategory
              title={title}
              data={editingCategory}
              show={isOpenAddCategory}
              handleClose={() => setOpenAddCategory(false)}
              handleSave={handleSave}
              disabled={
                !hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.UPDATE) ||
                editingCategory?.fromMasterData
              }
            />
          </div>
        </>
      ) : (
        <> </>
      )}
    </>
  );
};

export default DefineCategories;
