import React from 'react';
import PropTypes from 'prop-types';
import {
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
  useQuery
} from 'react-query';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  runTransaction,
  Timestamp
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import firebaseApp from './firebase';
import locationFacility from '../assets/facility_location.json';
import { DEFAULT_PERMISSION } from './Constant';
import moment from 'moment';

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

/* HELPERS */

// Store Firestore unsubscribe functions
const unsubs = {};

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (d) => {
    const docData = d.data();
    delete docData.id; // if an id field was saved on the doc, remove it
    return { id: d.id, ...docData };
  };

  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  }
  // Handle a single doc
  return response.exists() ? formatDoc(response) : null;
}

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const dataResponse = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(dataResponse);
          } else {
            client.setQueryData(queryKey, dataResponse);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    if (unsubs[queryHash]) {
      unsubs[queryHash]();
    }

    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// ** clean values **
const cleanName = (name) => name.replace(/[.#$/[\]]/g, '');

/** ** USERS *** */

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ['user', { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, 'users', uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, 'users', uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, 'users', uid), data);
}

/* ITEMS */
/* Example query functions (modify to your needs) */

// Subscribe to all Companies
export function useGetCompanies() {
  return useQuery(
    ['companies'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'companies')))
  );
}

// Subscribe to all Divisions
export function useGetDivisions() {
  return useQuery(
    ['divisions'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'divisions')))
  );
}

// Subscribe to all Units
export function useGetUnits() {
  return useQuery(
    ['units'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'units')))
  );
}

// Subscribe to all Regions
export function useGetRegions() {
  return useQuery(
    ['regions'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'regions')))
  );
}

// Subscribe to CompanyType
export function useGetCompanyType() {
  return useQuery(
    ['companyType'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'companyType')))
  );
}

// Subscribe to CompanyType
export function useGetOrderOfParameters() {
  return useQuery(
    ['orderOfParameters'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'orderOfParameters')))
  );
}

// Subscribe to all Laboratories
export function useGetLaboratories() {
  return useQuery(
    ['laboratories'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'laboratories')))
  );
}

// Subscribe to all Departments
export function useGetDepartments() {
  return useQuery(
    ['departments'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'departments')))
  );
}

// Subscribe to all Departments
export function useGetOrganizations() {
  return useQuery(
    ['organizations'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'organizations')))
  );
}

// Subscribe to all Areas
export function useGetAreas() {
  return useQuery(
    ['areas'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'areas')))
  );
}

// Create scheduled samples
export function createScheduledSamples(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'scheduled_samples'
  });
}

// Subscribe to all scheduled samples
export function useGetScheduledSamples() {
  return useQuery(
    ['scheduled_samples'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'scheduled_samples')))
  );
}

// Subscribe to all scheduled samples
export function useGetSampleSchedules() {
  return useQuery(
    ['sample_schedules'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'sample_schedules')))
  );
}

// Subscribe to all Instruments
export function useGetInstruments() {
  return useQuery(
    ['instruments'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'instruments')))
  );
}

// Subscribe to all Personnel
export function useGetPersonnel() {
  return useQuery(
    ['personnel'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'personnel')))
  );
}

// Subscribe to all Setup Groups
export function useGetSetupGroups() {
  return useQuery(
    ['setup_groups'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'setup_groups')))
  );
}

// Subscribe to Index Value by id
export function useGetIndexValuesById(id) {
  return useQuery(
    ['index', { id }],
    createQuery(() => query(collection(db, 'index'), where('id', '==', id))),
    { enabled: !!id }
  );
}

// Subscribe to all Index Values
export function useGetIndexValues() {
  return useQuery(
    ['index'],
    createQuery(() => query(collection(db, 'index')))
  );
}

// Subscribe to all Summary Values
export function useGetSummaries() {
  return useQuery(
    ['summary'],
    createQuery(() => query(collection(db, 'summary')))
  );
}

// Get summary document for fullKey
export function useGetSummaryByFullKey(fullKey) {
  const dataToReturn = useQuery(
    ['summary', { fullKey }],
    createQuery(() =>
      query(collection(db, 'summary'), where(documentId(), 'in', cleanName(fullKey)))
    )
  );

  return dataToReturn;
}

// Get report documents for fullKey
export function useGetReportsByFullKey(fullKey) {
  return useQuery(
    ['reports', { fullKey }],
    createQuery(() =>
      query(
        collection(db, 'config'),
        where('fullKey', '==', fullKey),
        where('recType', '==', 'reports')
      )
    )
  );
}

// Create a new Report
export function createReport(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    createdAt: serverTimestamp(),
    recType: 'reports'
  });
}

// Subscribe to all Methods
export function useGetMethods() {
  return useQuery(
    ['methods'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'methods')))
  );
}

// Subscribe to all Methods
export function useGetDataEntry() {
  return useQuery(
    ['data_entry_demo'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'data_entry_demo')))
  );
}

// Subscribe to all Results
export function useGetResults() {
  return useQuery(
    ['data'],
    createQuery(() => query(collection(db, 'data'), limit(100)))
  );
}

// Subscribe to all Samples
export function useGetSamples() {
  return useQuery(
    ['samples'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'samples')))
  );
}

// Subscribe to all Data
export function useGetAllData() {
  return useQuery(
    ['data'],
    createQuery(() => query(collection(db, 'data')))
  );
}

// Subscribe to all Assessments
export function useGetAllAssessments() {
  return useQuery(
    ['assessments'],
    createQuery(() => query(collection(db, 'assessments')))
  );
}

// Subscribe to all Sample Properties
export function useGetSampleProperties() {
  return useQuery(
    ['sample_properties'],
    createQuery(() => query(collection(db, 'config'), where('recType', '==', 'sample_properties')))
  );
}

// Subscribe to all entities by shortKey
export function useEntitiesByShortKey(shortKey) {
  return useQuery(
    ['data', { shortKey }],
    createQuery(() => query(collection(db, 'data'), where('shortKey', '==', shortKey))),
    { enabled: !!shortKey }
  );
}

// Subscribe to all entities by fullKey
export function useEntitiesByFullKey(fullKey) {
  return useQuery(
    ['data', { fullKey }],
    createQuery(() => query(collection(db, 'data'), where('fullKey', '==', fullKey))),
    { enabled: !!fullKey }
  );
}

export function useFindAssessmentsByShortKey(shortKey) {
  return useQuery(
    ['assessments', { shortKey }],
    createQuery(() => query(collection(db, 'assessments'), where('shortKey', '==', shortKey))),
    { enabled: !!shortKey }
  );
}

export function useGetSilencedDataByShortKey(shortKey) {
  return useQuery(
    ['silenced', { shortKey }],
    createQuery(() => query(collection(db, 'silenced'), where('shortKey', '==', shortKey))),
    { enabled: !!shortKey }
  );
}

export function useFindNotificationsByLocation(location) {
  return useQuery(
    ['notifications', { location }],
    createQuery(() =>
      query(
        collection(db, 'config'),
        where('location', '==', location),
        where('recType', '==', 'notifications')
      )
    ),
    { enabled: !!location }
  );
}

export function useFindDataByMultipleParameters(method, sampleName, parameter) {
  return useQuery(
    [
      'data',
      {
        method,
        sampleName,
        parameter
      }
    ],
    createQuery(() =>
      query(
        collection(db, 'data'),
        where('method', '==', method),
        where('sampleName', '==', sampleName),
        where('parameter', '==', parameter)
      )
    ),
    { enabled: !!method }
  );
}

export function useFindControlStrategyConfigurationByFullKey(fullKey) {
  return useQuery(
    ['configurations', { fullKey }],
    createQuery(() =>
      query(
        collection(db, 'config'),
        where('fullKey', '==', fullKey),
        where('recType', '==', 'configurations'),
        where('type', '==', 'Control Strategy')
      )
    ),
    { enabled: !!fullKey }
  );
}

export function useFindChartGroupConfigurationByShortKey(shortKey) {
  return useQuery(
    ['configurations', { shortKey }],
    createQuery(() =>
      query(
        collection(db, 'config'),
        where('shortKey', '==', shortKey),
        where('recType', '==', 'configurations'),
        where('type', '==', 'Chart Group')
      )
    ),
    { enabled: !!shortKey }
  );
}

// Create a new Assessment
export function createAssessment(data) {
  return addDoc(collection(db, 'assessments'), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// Update an existing Assessment
export function updateAssessment(id, data) {
  return updateDoc(doc(db, 'assessments', id), data);
}

// Update an existing data point
export function updateData(id, dataIn) {
  const data = dataIn;
  if (data.id) {
    delete data.id;
  }
  return updateDoc(doc(db, 'data', id), data);
}

// Update an existing Summary
export function updateSummary(id, data) {
  return updateDoc(doc(db, 'summary', cleanName(id)), data);
}

// Create a new method
export function createMethod(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'methods',
    createdAt: serverTimestamp()
  });
}

export function createSampleSchedule(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'sample_schedules',
    createdAt: serverTimestamp()
  });
}

// Create a new personnel
export function createPersonnel(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'personnel',
    createdAt: serverTimestamp()
  });
}

// Create a new company
export function createCompany(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'companies',
    createdAt: serverTimestamp()
  });
}

// Create a new setup group
export function createSetupGroup(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'setup_groups',
    createdAt: serverTimestamp()
  });
}

// Create a new instrument
export function createInstrument(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'instruments',
    createdAt: serverTimestamp()
  });
}

// Create a new organization
export function createOrganization(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'organizations',
    createdAt: serverTimestamp()
  });
}

// Create a new silenced data point
export function createSilencedData(data) {
  return addDoc(collection(db, 'silenced'), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// Create a data entry
export function createDataEntry(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'data_entry_demo',
    createdAt: serverTimestamp()
  });
}

// Create a new division
export function createDivision(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'divisions',
    createdAt: serverTimestamp()
  });
}

// Create a new unit
export function createUnit(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'units',
    createdAt: serverTimestamp()
  });
}

// Create a new region
export function createRegion(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'regions',
    createdAt: serverTimestamp()
  });
}

// Create a new Company Type
export async function setCompanyType(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'config'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'config'), data.id), {
    ...data,
    recType: 'companyType',
    createdAt: serverTimestamp()
  });
}

// Create a new Order of Parameters
export async function setOrderOfParameters(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'config'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'config'), data.id), {
    ...data,
    recType: 'orderOfParameters',
    createdAt: serverTimestamp()
  });
}

// Create a new area
export function createArea(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'areas',
    createdAt: serverTimestamp()
  });
}

// Create a new laboratory
export function createLaboratory(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'laboratories',
    createdAt: serverTimestamp()
  });
}

// Create a new department
export function createDepartment(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'departments',
    createdAt: serverTimestamp()
  });
}

// Create a new sample
export function createSample(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'samples',
    createdAt: serverTimestamp()
  });
}

// Create a new sample property
export function createSampleProperty(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'sample_properties',
    createdAt: serverTimestamp()
  });
}

// Create a new configuration
export function createConfiguration(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'configurations',
    createdAt: serverTimestamp()
  });
}

// Create a new notification
export function createNotification(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'notifications',
    createdAt: serverTimestamp()
  });
}

// Update a notification
export function updateNotification(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Update an item
export function updateSample(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Update an item
export function updateSampleProperty(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Update an item
export function updateInstrument(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Update a sample schedule
export function updateSampleSchedule(data) {
  return updateDoc(doc(db, 'config', data.id), data);
}

// Update a Personnel
export function updatePersonnel(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Update a setup group
export function updateSetupGroup(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Update a company
export function updateCompany(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Update a method
export function updateMethod(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Update a configuration
export function updateConfiguration(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Delete a method
export function deleteMethod(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a sample shedule
export function deleteSampleSchedule(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a scheduled sample
export function deleteScheduledSample(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a sample
export function deleteSample(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete an instrument
export function deleteInstrument(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete an organization
export function deleteOrganization(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a division
export function deleteDivision(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a data point
export function deleteData(id) {
  return deleteDoc(doc(db, 'data', id));
}

// Delete an assessment
export function deleteAssessment(id) {
  return deleteDoc(doc(db, 'assessments', id));
}

// Delete a unit
export function deleteUnit(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a region
export function deleteRegion(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete an area
export function deleteArea(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a laboratory
export function deleteLaboratory(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a department
export function deleteDepartment(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a personnel
export function deletePersonnel(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a company
export function deleteCompany(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a setup group
export function deleteSetupGroup(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Delete a sample property
export function deleteSampleProperty(id) {
  return deleteDoc(doc(db, 'config', id));
}

// Create combine z score
export function createCombineZScoreParameter(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'combine_z_score',
    createdAt: serverTimestamp()
  });
}

// Update combine z score
export function updateCombineZScoreParameter(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Read combine z score
export async function readCombineZScoreParameter(shortKey) {
  const collectionRef = collection(db, 'config');
  const q = query(
    collectionRef,
    where('shortKey', '==', shortKey),
    where('recType', '==', 'combine_z_score')
  );

  let value;
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0] || {};
    const { id } = doc;
    value = {
      id,
      ...doc.data()
    };
  }

  return value;
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query: q }) => {
  if (type === 'observerRemoved' && q.getObserversCount() === 0 && unsubs[q.queryHash]) {
    // Call stored Firestore unsubscribe function
    unsubs[q.queryHash]();
    delete unsubs[q.queryHash];
  }
});

// React Query context provider that wraps our app
export function QueryClientProvider({ children }) {
  return <QueryClientProviderBase client={client}>{children}</QueryClientProviderBase>;
}

QueryClientProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// ILCP EXTERNAL

export async function getAllPrograms() {
  const collectionRef = collection(db, 'external_ilcp_program');
  const q = query(collectionRef);

  const values = {};

  const querySnapshot = await getDocs(q);
  const uniqueKeyValues = new Set();
  querySnapshot.forEach((d) => {
    const value = d.get('label');
    if (value) {
      uniqueKeyValues.add(value);
      values[value] = [...(values[value] ?? []), d.data()];
    }
  });

  return values;
}

export async function getAllProgramsByLabelAndProgramId(label, programId) {
  const collectionRef = collection(db, 'external_ilcp_program');
  const q = query(
    collectionRef,
    where('label', '==', label),
    where('programId', '==', programId),
    orderBy('round')
  );

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const { id } = d;
    const value = {
      id,
      ...d.data()
    };

    value.sitestdev = value.sitestdev || -1 > 0 ? value.sitestdev : 1;
    if (value) values.push(value);
  });

  return [...values];
}

export async function updatePrograms(programsToUpdate) {
  const collectionRef = collection(db, 'external_ilcp_program');

  const updates = programsToUpdate.map((p) => {
    const programRef = doc(collectionRef, p.id);
    const updated = { ...p };
    delete updated.id;
    return updateDoc(programRef, updated);
  });

  return Promise.all(updates);
}

export async function savePrograms(programsToSave) {
  const promises = programsToSave.map((program) =>
    addDoc(collection(db, 'external_ilcp_program'), program)
  );
  await Promise.all(promises);
}

export async function updateIlcpData(toUpdate) {
  const docRef = doc(collection(db, 'external_ilcp_data'), toUpdate.programId);
  await setDoc(docRef, toUpdate);
}

export async function getAllExternalData() {
  const collectionRef = collection(db, 'external_ilcp_data');
  const q = query(collectionRef);
  const array = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const items = d.data();
    if (items.name) array.push(items);
  });

  return array;
}

export async function saveDataSet(newDataSet) {
  const collectionRef = collection(db, 'external_ilcp_data');

  const programId = uuidv4();

  const ds = {
    ...newDataSet,
    programId
  };

  await setDoc(doc(collectionRef, programId), ds);

  return ds;
}

export async function getStcmpPrograms() {
  const collectionRef = collection(db, 'stdcmp_program');
  const q = query(collectionRef);

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const value = d.get('name');
    if (value) {
      values.push(d.data());
    }
  });

  return [...values];
}

export async function getProductDataByMethod(method, programId) {
  const collectionRef = collection(db, 'stdcmp_data');
  const q = query(
    collectionRef,
    where('method', '==', method),
    where('programId', '==', programId),
    orderBy('date', 'desc')
  );

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const { id } = d;

    const time = d.get('date');
    const date = new Date(time);

    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}-${date.getFullYear()}`;

    const value = {
      id,
      ...d.data(),
      formattedDate
    };
    if (value) values.push(value);
  });

  return [...values];
}

export async function saveStdcmpData(dataToSave) {
  const promises = dataToSave.map(async (dataIn) => {
    const data = dataIn;
    if (!data.sampleID) {
      data.sampleID = uuidv4();
    }
    const docRef = doc(collection(db, 'stdcmp_data'), data.sampleID);
    return setDoc(docRef, data).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error writing document: ', error);
    });
  });
  await Promise.all(promises);
}

export async function updateStdcmpPrograms(toUpdate) {
  const docRef = doc(collection(db, 'stdcmp_program'), toUpdate.programId);
  await setDoc(docRef, toUpdate).catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Error writing document: ', error);
  });
}

export async function updateStdcmpData(listUpdate) {
  for (const toUpdate of listUpdate) {
    const dataUpdate = { ...toUpdate };
    delete dataUpdate.formattedDate;
    const docRef = doc(collection(db, 'stdcmp_data'), dataUpdate.id);
    await setDoc(docRef, dataUpdate);
  }
}

export async function addNewStdcmpData(listAddNew) {
  const collectionRef = collection(db, 'stdcmp_data');
  for (const toAddNew of listAddNew) {
    const dataUpdate = { ...toAddNew };
    delete dataUpdate.formattedDate;
    const data = await addDoc(collectionRef, {});
    const docRef = doc(collection(db, 'stdcmp_data'), data.id);
    await setDoc(docRef, { ...dataUpdate, id: data.id });
  }
}

export async function getInternalData() {
  const collectionRef = collection(db, 'internal_ilcp_data');

  const q = query(collectionRef);

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const { id } = d;

    const value = { id, ...d.data() };
    if (value) values.push(value);
  });

  return [...values];
}

export async function getInternalRun(id) {
  const docRef = doc(db, 'internal_ilcp_run', id);
  return getDoc(docRef).then((d) => d.data());
}

export async function getInternalRunData() {
  const collectionRef = collection(db, 'internal_ilcp_run');
  const values = {};
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((d) => {
    const { id } = d;

    const value = d.data();
    if (value) values[id] = value;
  });

  return { ...values };
}

export async function getInternalMetadata() {
  const collectionRef = collection(db, 'internal_ilcp_metadata');
  const values = {};
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((d) => {
    const { id } = d;

    const value = d.data();
    if (value) values[id] = value;
  });

  return { ...values };
}

export async function updateInternalMetadata(toUpdate) {
  const docRef = doc(collection(db, 'internal_ilcp_metadata'), toUpdate.key);
  await setDoc(docRef, toUpdate);
}

export async function updateMultipleInternalMetadata(metaDatas) {
  const collectionRef = collection(db, 'internal_ilcp_metadata');
  const updates = [];
  metaDatas.forEach((metaData) => {
    updates.push(setDoc(doc(collectionRef, metaData.key), metaData));
  });
  await Promise.all(updates);
}

export async function updateRun(toUpdate, key) {
  const docRef = doc(collection(db, 'internal_ilcp_run'), key);
  await setDoc(docRef, toUpdate);
}

export async function getLocations() {
  const collectionRef = collection(db, 'index');
  const q = query(collectionRef, where('aggType', '==', 'location'));

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const i = d.get('index');

    i.forEach((d2) => {
      if (d2.name) values.push(d2.name);
    });
  });

  return [...values];
}

export async function saveCalculation(calculation) {
  const collectionRef = collection(db, 'external_ilcp_calculations');
  const id = `${calculation.programId}-${calculation.fuelType}-${calculation.round}`;

  const docRef = doc(collectionRef, id);
  await setDoc(docRef, calculation);
}

export async function getCalculations(programId) {
  const collectionRef = collection(db, 'external_ilcp_calculations');
  const q = query(collectionRef, where('programId', '==', programId));

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const { id } = d;
    const value = { id, ...d.data() };
    if (value) values.push(value);
  });

  return [...values];
}

// this function should be call if we update data location in file assets/facility_location.json
export async function initLocationData() {
  const locationMap = new Map(locationFacility.map((element) => [element.facilityCode, element]));

  const collectionRef = collection(db, 'facility_location');

  const q = query(collectionRef);

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const { id } = d;
    const { facilityCode, locationId, locationName } = d.data();
    const value = locationMap.get(facilityCode);
    if (value) {
      if (value.locationId !== locationId || locationName !== value.locationName) {
        updateDoc(doc(db, 'facility_location', id), { facilityCode, locationId, locationName });
      }
      locationMap.delete(facilityCode);
    } else {
      deleteDoc(doc(db, 'facility_location', id));
    }
  });

  locationMap.forEach((location) => {
    const newLocationRef = doc(collection(db, 'facility_location'));
    setDoc(newLocationRef, location);
  });
}

export async function getLocationData() {
  const collectionRef = collection(db, 'facility_location');
  const result = new Map();
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((d) => {
    const { facilityCode, locationId, locationName } = d.data();
    if (facilityCode && locationName) {
      result.set(facilityCode, { locationId, locationName });
    }
  });
  // check list location on local source and db
  if (result.size !== locationFacility.length) {
    await initLocationData();
    result.clear();
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((d) => {
      const { facilityCode, locationId, locationName } = d.data();
      if (facilityCode && locationName) {
        result.set(facilityCode, { locationId, locationName });
      }
    });
  }
  return result;
}

export async function useGetPermisisonData() {
  try {
    const collectionRef = collection(db, 'permission');
    const result = {};
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((d) => {
      const data = d.data();
      const { id } = d;
      const permissionValue = {};
      Object.keys(data).forEach((key) => {
        permissionValue[key] = data[key];
      });
      permissionValue['permissionType'] = id;
      result[id] = permissionValue;
    });
    return result;
  } catch (err) {
    // return default permission config
    return DEFAULT_PERMISSION;
  }
}

export async function updatePermisison(id, data) {
  try {
    await setDoc(doc(db, 'permission', id), data);
    return { success: true };
  } catch (err) {
    return { success: false, message: `${err.message}` };
  }
}

export async function unArchiveSimple({ id, fullKey, data }) {
  try {
    await Promise.all(
      data.map(async (item) => {
        await setDoc(doc(db, 'data', item.id), { fullKey, archive: false });
      })
    );
    await setDoc(doc(db, 'config', id), { fullKey, archive: 'No' });
    return { success: true };
  } catch (err) {
    return { success: false, message: `${err.message}` };
  }
}

// useInstrumentSettings
export function useInstrumentSettings(type) {
  return useQuery(
    ['inventorySettings', { type }],
    createQuery(() => query(collection(db, 'settings'), where('type', '==', type))),
    { enabled: !!type }
  );
}

// useEventNumberSettings
export function useEventNumberSettings() {
  const type = 'EVENT_NUMBER';
  return useQuery(
    ['eventNumberSettings', { type }],
    createQuery(() => query(collection(db, 'settings'), where('type', '==', type))),
    { enabled: !!type }
  );
}

// update event number
export async function setInstrumentEventNumber(number) {
  const type = 'EVENT_NUMBER';
  const refConfig = collection(db, 'settings');
  const q = query(refConfig, where('type', '==', type));

  const querySnapshot = await getDocs(q);
  let enDocRef;
  if (!querySnapshot.empty) {
    enDocRef = querySnapshot.docs[0].ref;
    setDoc(enDocRef, { eventNumber: number, type: type });
  } else {
    enDocRef = await addDoc(refConfig, {
      type: type,
      eventNumber: number
    });
  }
}

// Create a new InstrumentSettings
export async function setInstrumentSettings(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'settings'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'settings'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentSettings
export async function deleteInstrumentSettings(data) {
  return deleteDoc(doc(db, 'settings', data.id));
}

// useInstrumentEvents
export function useInstrumentEvents(filter) {
  const { instrumentName, vendorId, parentEventId, responsiblePartyId, status, eventType, date } =
    filter || {};
  if (instrumentName) {
    return useQuery(
      ['inventoryEvents', { ...filter }],
      createQuery(() =>
        query(collection(db, 'events'), where('instrumentName', '==', instrumentName))
      )
    );
  } else if (vendorId) {
    return useQuery(
      ['inventoryEvents', { ...filter }],
      createQuery(() =>
        query(collection(db, 'events'), where('vendors', 'array-contains', vendorId))
      )
    );
  } else if (parentEventId) {
    return useQuery(
      ['inventoryEvents', { ...filter }],
      createQuery(() =>
        query(collection(db, 'events'), where('parentEventId', '==', parentEventId))
      )
    );
  } else if (responsiblePartyId) {
    return useQuery(
      ['inventoryEvents', { ...filter }],
      createQuery(() =>
        query(collection(db, 'events'), where('responsibleParty', '==', responsiblePartyId))
      )
    );
  } else {
    const conditions = [];

    if (date) {
      var filterDate = new Date();
      filterDate.setDate(filterDate.getDate() + parseInt(date));

      const timestampFilterDate = Timestamp.fromDate(filterDate);
      const timestampCurrentDate = Timestamp.fromDate(new Date());

      conditions.push(
        where('dateDue', '<=', date > 0 ? timestampFilterDate : timestampCurrentDate)
      );
      conditions.push(
        where('dateDue', '>=', date > 0 ? timestampCurrentDate : timestampFilterDate)
      );
    }

    if (status) {
      conditions.push(where('status', '==', status));
    }

    if (eventType) {
      conditions.push(where('eventType', '==', eventType));
    }

    return useQuery(
      ['inventoryEvents', { ...filter }],
      createQuery(() => query(collection(db, 'events'), ...conditions))
    );
  }
}

// useInstrumentEvents
export function useInstrumentEventDetails(id) {
  return useQuery(
    ['inventoryEquipmentDetails', { id }],
    createQuery(() => query(collection(db, 'events'), where('id', '==', id)))
  );
}

export async function getEventNumberSettingsRef() {
  const refConfig = collection(db, 'settings');
  const q = query(refConfig, where('type', '==', 'EVENT_NUMBER'));
  const querySnapshot = await getDocs(q);
  let enDocRef;
  if (!querySnapshot.empty) {
    enDocRef = querySnapshot.docs[0].ref;
  } else {
    enDocRef = await addDoc(refConfig, {
      type: 'EVENT_NUMBER',
      sequential: 0,
      dateSection: moment().format('YYMM')
    });
  }

  return enDocRef;
}

export async function getNewEventNumber(enDocRef, transaction) {
  const eventNumberDoc = await transaction.get(enDocRef);
  if (!eventNumberDoc.exists()) {
    throw 'Document does not exist!';
  }
  if (moment().format('YYMM') !== eventNumberDoc.data().dateSection) {
    return {
      dateSection: moment().format('YYMM'),
      sequential: 0
    };
  } else {
    return {
      dateSection: eventNumberDoc.data().dateSection,
      sequential: eventNumberDoc.data().sequential
    };
  }
}

// Create a new InstrumentEvents
export async function setInstrumentEvents(data) {
  const ref = collection(db, 'events');
  // clear variable undefine before request
  Object.keys(data).forEach((key) => data[key] === undefined && delete data[key]);
  // update event exist.
  if (data.id) {
    await setDoc(doc(ref, data.id), {
      ...data,
      updatedAt: serverTimestamp()
    });
  } else {
    // create new event.
    const enDocRef = await getEventNumberSettingsRef();
    const docRef = await addDoc(ref, { ...data });
    data.id = docRef.id;
    try {
      await runTransaction(db, async (transaction) => {
        const newEventNumber = await getNewEventNumber(enDocRef, transaction);

        newEventNumber.sequential += 1;

        await setDoc(docRef, {
          ...data,
          eventNumber: `${newEventNumber.dateSection}${newEventNumber.sequential
            .toString()
            .padStart(4, '0')}`,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
        transaction.update(enDocRef, newEventNumber);
      });
    } catch (e) {
      console.log('Transaction failed: ', e);
    }
  }
  return data;
}

// delete InstrumentEvents
export async function deleteInstrumentEvents(data) {
  return deleteDoc(doc(db, 'events', data.id));
}

// useInstrumentEquipments
export function useInstrumentEquipments(filter) {
  const { vendorId, location, status, type, parameter, serialNumber, responsiblePartyId } =
    filter || {};
  if (vendorId) {
    return useQuery(
      ['inventoryEquipments', { ...filter }],
      createQuery(() =>
        query(collection(db, 'equipments'), where('vendors', 'array-contains', vendorId))
      )
    );
  } else if (responsiblePartyId) {
    return useQuery(
      ['inventoryEquipments', { ...filter }],
      createQuery(() =>
        query(collection(db, 'equipments'), where('responsibleParty', '==', responsiblePartyId))
      )
    );
  } else {
    const conditions = [];

    if (location) {
      conditions.push(where('location', '==', location));
    }
    if (status) {
      conditions.push(where('status', '==', status));
    }
    if (type) {
      conditions.push(where('type', '==', type));
    }
    if (parameter) {
      conditions.push(where('parameter', '==', parameter));
    }
    if (serialNumber) {
      conditions.push(where('serialNumber', '==', serialNumber));
    }
    return useQuery(
      ['inventoryEquipments', { ...filter }],
      createQuery(() => query(collection(db, 'equipments'), ...conditions))
    );
  }
}

// useInstrumentEquipments
export function useInstrumentEquipmentDetails(id) {
  return useQuery(
    ['inventoryEquipmentDetails', { id }],
    createQuery(() => query(collection(db, 'equipments'), where('id', '==', id)))
  );
}

// Create a new InstrumentEquipments
export async function setInstrumentEquipments(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'equipments'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'equipments'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentEquipments
export async function deleteInstrumentEquipments(data) {
  return deleteDoc(doc(db, 'equipments', data.id));
}

// useInstrumentVendors
export function useInstrumentVendors(filter) {
  const { companyName, status, id } = filter || {};
  const conditions = [];

  if (companyName) {
    conditions.push(where('companyName', '==', companyName));
  }
  if (status) {
    conditions.push(where('status', '==', status));
  }

  if (id) {
    conditions.push(where('vendorId', '==', id));
  }

  return useQuery(
    ['inventoryVendors', { filter }],
    createQuery(() => query(collection(db, 'vendors'), ...conditions))
  );
}

// useInstrumentVendors
export function useInstrumentVendorDetails(id) {
  return useQuery(
    ['inventoryVendorDetails', { id }],
    createQuery(() => query(collection(db, 'vendors'), where('id', '==', id)))
  );
}

// Create a new InstrumentVendors
export async function setInstrumentVendors(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'vendors'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'vendors'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentVendors
export async function deleteInstrumentVendors(data) {
  return deleteDoc(doc(db, 'vendors', data.id));
}

// useInstrumentDocuments
export function useInstrumentDocuments(filter) {
  const { location, documentType, instrumentId } = filter || {};
  const conditions = [];

  if (location) {
    conditions.push(where('location', '==', location));
  }

  if (documentType) {
    conditions.push(where('documentType', '==', documentType));
  }

  if (instrumentId) {
    conditions.push(where('instrumentId', '==', instrumentId));
  }

  return useQuery(
    ['inventoryDocuments', { ...filter }],
    createQuery(() => query(collection(db, 'documents'), ...conditions))
  );
}

// useInstrumentDocuments
export function useInstrumentDocumentDetails(id) {
  return useQuery(
    ['inventoryDocumentDetails', { id }],
    createQuery(() => query(collection(db, 'documents'), where('id', '==', id)))
  );
}

// Create a new InstrumentDocuments
export async function setInstrumentDocuments(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'documents'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'documents'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentDocuments
export async function deleteInstrumentDocuments(data) {
  return deleteDoc(doc(db, 'documents', data.id));
}

// useInstrumentNotifications
export function useInstrumentNotifications(filter) {
  const { eventId } = filter;
  return useQuery(
    ['inventoryNotifications', { ...filter }],
    createQuery(() => query(collection(db, 'notification_groups'), where('eventId', '==', eventId)))
  );
}

// Create a new InstrumentNotifications
export async function setInstrumentNotifications(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'notification_groups'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'notification_groups'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentNotifications
export async function deleteInstrumentNotifications(data) {
  return deleteDoc(doc(db, 'notification_groups', data.id));
}

// useInstrumentNotificationGroups
export function useInstrumentNotificationGroups() {
  return useQuery(
    ['inventoryNotifications'],
    createQuery(() => query(collection(db, 'notification_groups')))
  );
}

// Create a new InstrumentNotificationGroups
export async function setInstrumentNotificationGroups(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'notification_groups'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'notification_groups'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentNotificationGroups
export async function deleteInstrumentNotificationGroups(data) {
  return deleteDoc(doc(db, 'notification_groups', data.id));
}

// Create a new InstrumentStatusLogs
export async function setInstrumentStatusLog(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'instrument_status_logs'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'instrument_status_logs'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

export function getInstrumentStatusLog(documentId, type) {
  return useQuery(
    ['instrumentStatusLog', { documentId, type }],
    createQuery(() =>
      query(
        collection(db, 'instrument_status_logs'),
        where('type', '==', type),
        where('documentId', '==', documentId)
      )
    )
  );
}

export async function ImportInstrumentEquipments(instruments) {
  try {
    const enDocRef = await getEventNumberSettingsRef();

    await runTransaction(db, async (transaction) => {
      const newEventNumber = await getNewEventNumber(enDocRef, transaction);

      instruments.forEach((instrument) => {
        const newInstrumentRef = doc(collection(db, 'equipments'));
        let { events, ...instrumentFields } = instrument;
        transaction.set(newInstrumentRef, {
          ...instrumentFields,
          id: newInstrumentRef.id
        });

        events.forEach((event) => {
          newEventNumber.sequential += 1;

          const newEventRef = doc(collection(db, 'events'));

          transaction.set(newEventRef, {
            ...event,
            id: newEventRef.id,
            instrumentName: newInstrumentRef.id,
            eventNumber: `${newEventNumber.dateSection}${newEventNumber.sequential
              .toString()
              .padStart(4, '0')}`,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          });
        });

        transaction.update(enDocRef, newEventNumber);
      });
    });
  } catch (e) {
    console.log('Transaction failed: ', e);
  }
}
